module Index

open Client
open Client.DomainTypes
open Client.Domain
open Client.Model
open Client.Msg
open Client.Session
open Client.Page
open Fable.Core.JsInterop
open Elmish
open Fable.FontAwesome
open Fable.React
open Fulma
open Leaflet
open Client.Navbar
open Shared.Dto.Dto
open Shared.Dto.User

importAll "flatpickr/dist/themes/material_green.css"
importAll "../../node_modules/leaflet/dist/leaflet.css"
icon?Default?imagePath <- "//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/"

importAll "./Styles/main.css"
importAll "./Styles/map.css"
importAll "./Styles/graph.css"


let private mapModel (modelMap: 'model -> PageModel) (subModel: 'model, cmd: Cmd<Msg>) = modelMap subModel, cmd

let onlyLoggedIn route =
    onlyForLoggedInSession (Login.init route >> mapModel PageModel.Login)


let urlUpdate (maybeRoute: Route option) (model: Model) : Model * Cmd<Msg> =
    let route = Option.defaultValue Route.NotFound maybeRoute

    let map (modelMap: 'model -> PageModel) (cmdMap: 'msg -> Msg) (subModel: 'model, subCmd: Cmd<'msg>) =
        modelMap subModel, Cmd.map cmdMap subCmd

    let onlyWithSession =
        initWithSession (PendingSession.init route |> PageModel.PendingSession, Cmd.none) model.Session

    let model, pageCmd =
        match route with
        | Route.Home ->
            onlyWithSession (onlyLoggedIn route (Home.init >> mapModel PageModel.Home))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.TtnSensorList ->
            onlyWithSession (onlyLoggedIn route (PhysicalSensorList.init >> mapModel PageModel.TtnSensorList))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.MySensSensorList ->
            onlyWithSession (onlyLoggedIn route (MapSensorList.init >> mapModel PageModel.MySensSensorList))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.GatewayList ->
            onlyWithSession (onlyLoggedIn route (GatewayList.init >> mapModel PageModel.GatewayList))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.PublicMap ->
            onlyWithSession (fun _ -> PublicMap.init |> map PublicPage PublicMap)
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.SensorMap _ ->
            onlyWithSession (onlyLoggedIn route (SensorMap.init >> map PageModel.SensorPage Msg.Map))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.Login ->
            onlyWithSession (Login.init Route.Home >> mapModel Model.Login)
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.NotFound ->
            onlyWithSession (fun _ -> NotFound, Cmd.none)
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.PasswordLost ->
            onlyWithSession (fun _ -> PageModel.PasswordLost PasswordLost.init, Cmd.none)
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.ResetPassword token ->
            onlyWithSession (ResetPassword.init token >> mapModel Model.ResetPassword)
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.MySettings ->
            onlyWithSession (onlyLoggedIn route (MySettings.init >> mapModel PageModel.MySettings))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.UserList ->
            onlyWithSession (onlyLoggedIn route (UserList.init >> mapModel PageModel.UserList))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.CalculationConfiguration ->
            onlyWithSession (
                onlyLoggedIn route (CalculationConfiguration.init >> mapModel PageModel.CalculationConfiguration)
            )
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.MySensSensor id ->
            onlyWithSession (onlyLoggedIn route (MapSensorDataPage.init id >> mapModel PageModel.MapSensorData))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.AlertsList ->
            onlyWithSession (onlyLoggedIn route (AlertsList.init >> mapModel PageModel.AlertsList))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.MyAlerts ->
            onlyWithSession (onlyLoggedIn route (MyAlerts.init >> mapModel PageModel.MyAlerts))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.MySensorsData ->
            onlyWithSession (onlyLoggedIn route (Page.MySensorsData.init >> mapModel PageModel.MySensorsData))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.MyMapSensors ->
            onlyWithSession (onlyLoggedIn route (Page.MyMapSensors.init >> mapModel PageModel.MyMapSensors))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.RimPro ->
            onlyWithSession (onlyLoggedIn route (Page.RimPro.init >> mapModel PageModel.RimPro))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.MyGateways ->
            onlyWithSession (onlyLoggedIn route (Page.MyGateways.init >> mapModel PageModel.MyGateways))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.MyScabStations ->
            onlyWithSession (onlyLoggedIn route (Page.MyScabStations.init >> mapModel PageModel.MyScabStations))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.ScabData stationId ->
            onlyWithSession (onlyLoggedIn route (Page.ScabData.init stationId >> mapModel PageModel.ScabData))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.MyUserGroups ->
            onlyWithSession (onlyLoggedIn route (Page.MyUserGroups.init >> mapModel PageModel.MyUserGroups))
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.UserDefinedMapSensorProperties id ->
            onlyWithSession (
                onlyLoggedIn
                    route
                    (Page.UserDefinedMapSensorProperties.init id
                     >> mapModel PageModel.UserDefinedMapSensorSettings)
            )
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.MyPeronosporaStations ->
            onlyWithSession (
                onlyLoggedIn route (Page.MyPeronosporaStations.init >> mapModel PageModel.MyPeronosporaStations)
            )
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)
        | Route.PeronosporaData stationId ->
            onlyWithSession (
                onlyLoggedIn route (Page.PeronosporaData.init stationId >> mapModel PageModel.PeronosporaData)
            )
            |> (fun (pageModel, cmd) -> { model with Page = pageModel }, cmd)


    model, pageCmd

let heroView (user: UserDto option) (dispatch: Msg -> Unit) (content: ReactElement) =
    div [] [
        createMainNavbar user (Global >> dispatch)
        Hero.hero [ Hero.IsFullheightWithNavbar ] [
            content
            Hero.foot [] [ PageSkeleton.mySensFooter ]
        ]
    ]

let mapCmd (model: Model) (modelMap: 'model -> PageModel) (cmdMap: 'msg -> Msg) (subModel: 'model, subCmd: Cmd<'msg>) =
    let newModel = { model with Page = modelMap subModel }

    newModel, Cmd.map cmdMap subCmd

let toggleBurger (model: Model) = { model with IsBurgerOpen = not model.IsBurgerOpen }

let closeBurger (model: Model) = { model with IsBurgerOpen = false }

let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
    let mapModel (modelMap: 'model -> PageModel) (subModel: 'model, cmd: Cmd<Msg>) = modelMap subModel, cmd

    match (msg, model.Page) with
    | Global LogoutPressed, _ ->
        match model.Session with
        | Pending -> model, Cmd.none
        | Session userSession -> model, runLogout userSession |> Cmd.map Global
    | Global LoggedOut, _ ->
        let session = Anonymous

        Page.Login.init Route.Home session |> mapCmd model Model.Login id
    | Global(SessionReceived session), PendingSession(PendingSession.Loading route) ->
        urlUpdate (Some route) { model with Session = Session session }
    | Global(GoToRoute route), _ ->
        model
        |> closeBurger
        |> (fun model ->
            let additionalCommands = Routing.routeToAdditionalCommands route
            let navCmd = route |> Routing.routeToUrl |> Navigation.Navigation.newUrl

            model, Cmd.batch [ navCmd; additionalCommands ]
        )
    | Global ToggleBurger, _ -> toggleBurger model, Cmd.none
    | PublicMap pageMsg, PublicPage pageModel ->
        PublicMap.update pageMsg pageModel
        |> mapCmd model Model.PublicPage Msg.PublicMap
    | Map pageMsg, SensorPage pageModel -> SensorMap.update pageMsg pageModel |> mapCmd model Model.SensorPage Map
    | Login loginMsg, PageModel.Login loginModel ->
        let clientSession =
            match loginMsg with
            | LoginResult(Successful userSession) ->
                storeClientSessionId (sessionKeyToString userSession.SessionKey)

                Session(UserSession userSession)
            | _ -> model.Session

        Login.update loginMsg loginModel
        |> mapModel Model.Login
        |> fun (pageModel, cmd) ->
            {
                model with
                    Page = pageModel
                    Session = clientSession
            },
            cmd
    | Msg.Home dataMsg, PageModel.Home dataModel ->
        Page.Home.update dataMsg dataModel
        |> fun (pageModel, cmd) -> { model with Page = PageModel.Home pageModel }, cmd
    | TtnSensorList ttnMsg, PageModel.TtnSensorList listModel ->
        PhysicalSensorList.update ttnMsg listModel
        |> mapModel Model.TtnSensorList
        |> fun (pageModel, cmd) -> { model with Page = pageModel }, cmd
    | MapSensorList mySensMsg, PageModel.MySensSensorList listModel ->
        MapSensorList.update mySensMsg listModel
        |> mapModel Model.MySensSensorList
        |> fun (pageModel, cmd) -> { model with Page = pageModel }, cmd
    | GatewayList gatewayMsg, PageModel.GatewayList listModel ->
        GatewayList.update gatewayMsg listModel
        |> mapModel Model.GatewayList
        |> fun (pageModel, cmd) -> { model with Page = pageModel }, cmd
    | MySettings mySettingsMsg, PageModel.MySettings profileModel ->
        MySettings.update mySettingsMsg profileModel
        |> fun (pageModel, cmd) -> { model with Page = PageModel.MySettings pageModel }, cmd
    | UserList userListMsg, PageModel.UserList userListModel ->
        UserList.update userListMsg userListModel
        |> fun (pageModel, cmd) -> { model with Page = PageModel.UserList pageModel }, cmd
    | PasswordLost lostMsg, PageModel.PasswordLost lostModel ->
        PasswordLost.update lostMsg lostModel
        |> fun (pageModel, cmd) -> { model with Page = PageModel.PasswordLost pageModel }, cmd
    | ResetPassword resetMsg, PageModel.ResetPassword resetModel ->
        ResetPassword.update resetMsg resetModel
        |> fun (pageModel, cmd) -> { model with Page = PageModel.ResetPassword pageModel }, cmd
    | CalculationConfiguration calcConfigurationMsg, PageModel.CalculationConfiguration calcConfigurationModel ->
        CalculationConfiguration.update calcConfigurationMsg calcConfigurationModel
        |> fun (pageModel, cmd) -> { model with Page = PageModel.CalculationConfiguration pageModel }, cmd
    | MapSensorData sensorMsg, PageModel.MapSensorData sensorModel ->
        MapSensorDataPage.update sensorMsg sensorModel
        |> fun (pageModel, cmd) -> { model with Page = PageModel.MapSensorData pageModel }, cmd
    | AlertsList alertsMsg, PageModel.AlertsList alertsModel ->
        AlertsList.update alertsMsg alertsModel
        |> fun (pageModel, cmd) -> { model with Page = PageModel.AlertsList pageModel }, cmd
    | MyAlerts alertsMsg, PageModel.MyAlerts alertsModel ->
        MyAlerts.update alertsMsg alertsModel
        |> fun (pageModel, cmd) -> { model with Page = PageModel.MyAlerts pageModel }, cmd
    | MySensorsData dataMsg, PageModel.MySensorsData dataModel ->
        MySensorsData.update dataMsg dataModel
        |> fun (pageModel, cmd) -> { model with Page = PageModel.MySensorsData pageModel }, cmd
    | UserDefinedMapSensorProperties dataMsg, PageModel.UserDefinedMapSensorSettings dataModel ->
        UserDefinedMapSensorProperties.update dataMsg dataModel
        |> fun (pageModel, cmd) -> { model with Page = PageModel.UserDefinedMapSensorSettings pageModel }, cmd
    | Msg.MyMapSensors dataMsg, PageModel.MyMapSensors dataModel ->
        Page.MyMapSensors.update dataMsg dataModel
        |> fun (pageModel, cmd) -> { model with Page = PageModel.MyMapSensors pageModel }, cmd
    | Msg.RimPro subMsg, PageModel.RimPro pageModel ->
        Page.RimPro.update subMsg pageModel
        |> fun (pageModel, cmd) -> { model with Page = PageModel.RimPro pageModel }, cmd
    | Msg.MyGateways subMsg, PageModel.MyGateways pageModel ->
        Page.MyGateways.update subMsg pageModel
        |> fun (pageModel, cmd) -> { model with Page = PageModel.MyGateways pageModel }, cmd
    | Msg.ScabData subMsg, PageModel.ScabData pageModel ->
        Page.ScabData.update subMsg pageModel
        |> fun (pageModel, cmd) -> { model with Page = PageModel.ScabData pageModel }, cmd
    | Msg.MyScabStations subMsg, PageModel.MyScabStations pageModel ->
        Page.MyScabStations.update subMsg pageModel
        |> fun (pageModel, cmd) -> { model with Page = PageModel.MyScabStations pageModel }, cmd
    | Msg.MyUserGroups subMsg, PageModel.MyUserGroups pageModel ->
        Page.MyUserGroups.update subMsg pageModel
        |> fun (pageModel, cmd) -> { model with Page = PageModel.MyUserGroups pageModel }, cmd
    | Msg.MyPeronosporaStations subMsg, PageModel.MyPeronosporaStations pageModel ->
        Page.MyPeronosporaStations.update subMsg pageModel
        |> fun (pageModel, cmd) -> { model with Page = PageModel.MyPeronosporaStations pageModel }, cmd
    | Msg.PeronosporaData subMsg, PageModel.PeronosporaData pageModel ->
        Page.PeronosporaData.update subMsg pageModel
        |> fun (pageModel, cmd) -> { model with Page = PageModel.PeronosporaData pageModel }, cmd
    | _, _ -> model, Cmd.none

let view (model: Model) (dispatch: Msg -> unit) =
    let user () =
        model.Session |> getUserFromClientSession |> Option.get

    let pageSkeletonFactory content =
        PageSkeleton.skeleton (user ()) model.IsBurgerOpen dispatch content

    let pageSkeletonSingleElementFactory content = pageSkeletonFactory [ content ]

    match model.Page with
    | PublicPage pageModel -> PublicMap.view pageModel dispatch
    | SensorPage pageModel ->
        SensorMap.view pageModel dispatch
        |> PageSkeleton.skeletonWithoutContentWrapper (user ()) model.IsBurgerOpen dispatch
    | Model.Login pageModel ->
        Page.Login.view pageModel dispatch
        |> heroView (getUserFromClientSession model.Session) dispatch
    | NotFound -> Page.NotFound.view |> heroView (getUserFromClientSession model.Session) dispatch
    | PendingSession _ -> Loadable.loadingView (Some "Warten auf Daten vom Server") Fa.Fa8x
    | Model.TtnSensorList listModel ->
        Page.PhysicalSensorList.view listModel dispatch
        |> pageSkeletonSingleElementFactory
    | Model.MySensSensorList listModel -> Page.MapSensorList.view listModel dispatch |> pageSkeletonSingleElementFactory
    | Model.Home model -> Page.Home.view model dispatch |> pageSkeletonSingleElementFactory
    | Model.MySettings profileModel ->
        MySettings.view (MySettings >> dispatch) profileModel
        |> pageSkeletonSingleElementFactory
    | Model.UserList userListModel -> UserList.view userListModel dispatch |> pageSkeletonSingleElementFactory
    | Model.PasswordLost lostModel -> Page.PasswordLost.view lostModel dispatch |> heroView None dispatch
    | Model.ResetPassword resetModel -> Page.ResetPassword.view resetModel dispatch |> heroView None dispatch
    | Model.GatewayList listModel -> Page.GatewayList.view listModel dispatch |> pageSkeletonSingleElementFactory
    | Model.CalculationConfiguration calcConfigurationModel ->
        Page.CalculationConfiguration.view (CalculationConfiguration >> dispatch) calcConfigurationModel
        |> pageSkeletonSingleElementFactory
    | Model.MapSensorData sensorModel -> MapSensorDataPage.view sensorModel dispatch |> pageSkeletonSingleElementFactory
    | Model.AlertsList alertsModel -> Page.AlertsList.view alertsModel dispatch |> pageSkeletonSingleElementFactory
    | Model.MyAlerts alertsModel -> Page.MyAlerts.view alertsModel dispatch |> pageSkeletonFactory
    | Model.MySensorsData dataModel -> Page.MySensorsData.view dataModel dispatch |> pageSkeletonSingleElementFactory
    | Model.UserDefinedMapSensorSettings dataModel ->
        Page.UserDefinedMapSensorProperties.view dataModel dispatch
        |> pageSkeletonSingleElementFactory
    | Model.MyMapSensors dataModel -> Page.MyMapSensors.view dispatch dataModel |> pageSkeletonSingleElementFactory
    | Model.RimPro pageModel -> Page.RimPro.view dispatch pageModel |> pageSkeletonFactory
    | Model.MyGateways pageModel -> Page.MyGateways.view pageModel |> pageSkeletonSingleElementFactory
    | Model.ScabData pageModel -> Page.ScabData.view dispatch pageModel |> pageSkeletonSingleElementFactory
    | Model.MyScabStations pageModel -> Page.MyScabStations.view dispatch pageModel |> pageSkeletonSingleElementFactory
    | Model.MyUserGroups pageModel -> Page.MyUserGroups.view pageModel |> pageSkeletonSingleElementFactory
    | Model.MyPeronosporaStations pageModel ->
        Page.MyPeronosporaStations.view dispatch pageModel
        |> pageSkeletonSingleElementFactory
    | Model.PeronosporaData pageModel ->
        Page.PeronosporaData.view dispatch pageModel |> pageSkeletonSingleElementFactory