module Client.Page.UserDefinedMapSensorProperties

open Browser.Types
open Browser.Navigator
open Client.Api
open Client.Domain
open Client.InfrastructureTypes
open Client.Msg
open Client
open Thoth.Elmish
open Fable.React.Props
open Fable.React.Standard
open Fable.React
open Elmish
open Feliz
open Fulma
open Shared
open Shared.Dto
open Shared.Dto.Dto
open Shared.Dto.MapSensorSettings
open Shared.Infrastructure

type BaseSettings = {
    TtnSensor: string option
    Name: string option
    Latitude: float option
    Longitude: float option
    Altitude: float option
}

type SoilSensorSettings = {
    WetLimit: double option
    DryLimit: double option
    BaseSettings: BaseSettings
}

type SensorSettings =
    | Base of BaseSettings
    | Soil of SoilSensorSettings

let private baseDtoToSettings (dto: BaseMapSensorSettingsDto) = {
    TtnSensor = dto.TtnSensor
    Name = dto.Name
    Latitude = Option.map (fun (location: Location) -> location.Latitude) dto.Location
    Longitude = Option.map (fun (location: Location) -> location.Longitude) dto.Location
    Altitude = dto.Altitude
}

let private updateBaseData (f: BaseSettings -> BaseSettings) (settings: SensorSettings) : SensorSettings =
    match settings with
    | Base baseSettings -> f baseSettings |> SensorSettings.Base
    | Soil soilSettings ->
        f soilSettings.BaseSettings
        |> (fun baseSettings -> SensorSettings.Soil { soilSettings with BaseSettings = baseSettings })

let private getBaseSettings (settings: SensorSettings) =
    match settings with
    | Base baseSettings -> baseSettings
    | Soil soilSettings -> soilSettings.BaseSettings

let private dtoToSensorSettings (dto: MapSensorSettingsDto) =
    match dto with
    | MapSensorSettingsDto.Base settings -> SensorSettings.Base(baseDtoToSettings settings)
    | MapSensorSettingsDto.Soil settings ->
        SensorSettings.Soil {
            WetLimit = settings.WetLimit
            DryLimit = settings.DryLimit
            BaseSettings = baseDtoToSettings settings.BaseSettings
        }

type DataModel = {
    SessionKey: SessionKey
    Id: int
    SensorSettings: SensorSettings
    DeviceLocationUpdateRunning: bool
    UpdateRequestRunning: bool
}

type Model = Loadable<DataModel, SessionKey>

let init id (session: UserSession) : Model * Cmd<Msg> =
    let requestData = {
        SessionKey = session.SessionKey
        Data = id
    }

    let cmd =
        Cmd.OfAsync.perform api.getMapSensorSettings requestData (DataReceived >> UserDefinedMapSensorProperties)

    Loadable.Loading session.SessionKey, cmd

let private createDataModel sessionKey (properties: MapSensorSettingsDto) : DataModel =
    let sensorSettings = dtoToSensorSettings properties

    let id =
        match properties with
        | MapSensorSettingsDto.Base baseSettings -> baseSettings.Id
        | MapSensorSettingsDto.Soil soilSettings -> soilSettings.BaseSettings.Id

    {
        SessionKey = sessionKey
        Id = id
        SensorSettings = sensorSettings
        DeviceLocationUpdateRunning = false
        UpdateRequestRunning = false
    }

let update (msg: UserDefinedMapSensorPropertiesMsg) (model: Model) =
    match (msg, model) with
    | DeviceLocationRequested, Loadable.Data data ->
        Loadable.Data { data with DeviceLocationUpdateRunning = true }, Cmd.none
    | DeviceLocationReceived position, Loadable.Data data ->
        let toastCmd =
            Toast.create "GPS Koordinaten des Sensors erfolgreich übernommen"
            |> Toast.success

        let newSensorSettings =
            updateBaseData
                (fun settings -> {
                    settings with
                        Latitude = Some position.coords.latitude
                        Longitude = Some position.coords.longitude
                        Altitude = position.coords.altitude |> Option.map (Math.round 0)
                })
                data.SensorSettings

        Loadable.Data {
            data with
                SensorSettings = newSensorSettings
                DeviceLocationUpdateRunning = false
        },
        toastCmd
    | DeviceLocationFailed _, Loadable.Data data ->
        let toastCmd =
            Toast.create "Beim Auslesen der GPS Koordinaten ist ein Fehler aufgetreten"
            |> Toast.error

        Loadable.Data { data with DeviceLocationUpdateRunning = false }, toastCmd
    | DataReceived response, Loadable.Loading sessionKey ->
        match response with
        | Result.Ok data -> Loadable.Data(createDataModel sessionKey data), Cmd.none
        | Result.Error(AuthErr err) ->
            let message =
                match err with
                | Unauthenticated -> "Du bist nicht eingeloggt, bitte lade Seite nochmal neu"
                | Unauthorized -> "Du darfst diesen Sensor nicht bearbeiten"

            Loadable.Error message, Cmd.none
        | Result.Error(CustomErr err) -> Loadable.Error err, Cmd.none
    | UpdateMapSensorProperties newProperties, Loadable.Data data ->
        let requestData = {
            SessionKey = data.SessionKey
            Data = newProperties
        }

        let cmd =
            Cmd.OfAsync.perform
                api.updateMapSensorSettings
                requestData
                (MapSensorPropertiesUpdated >> UserDefinedMapSensorProperties)

        Loadable.Data { data with UpdateRequestRunning = true }, cmd
    | MapSensorPropertiesUpdated result, Loadable.Data dataModel ->
        match result with
        | Result.Ok result ->
            let toastCmd =
                Toast.create "Die Daten des Sensors wurden erfolgreich aktualisiert"
                |> Toast.success

            Loadable.Data { dataModel with UpdateRequestRunning = false }, toastCmd
        | Result.Error _ ->
            Loadable.Error "Beim Speichern ist ein Fehler aufgetreten. Versuche es bitte erneut", Cmd.none
    | UpdateName name, Loadable.Data dataModel ->
        let newSensorSettings =
            updateBaseData (fun settings -> { settings with Name = name }) dataModel.SensorSettings

        Loadable.Data { dataModel with SensorSettings = newSensorSettings }, Cmd.none
    | UpdateLatitude latitude, Loadable.Data dataModel ->
        let newSensorSettings =
            updateBaseData (fun settings -> { settings with Latitude = latitude }) dataModel.SensorSettings

        Loadable.Data { dataModel with SensorSettings = newSensorSettings }, Cmd.none
    | UpdateLongitude longitude, Loadable.Data dataModel ->
        let newSensorSettings =
            updateBaseData (fun settings -> { settings with Longitude = longitude }) dataModel.SensorSettings

        Loadable.Data { dataModel with SensorSettings = newSensorSettings }, Cmd.none
    | UpdateAltitude altitude, Loadable.Data dataModel ->
        let newSensorSettings =
            updateBaseData (fun settings -> { settings with Altitude = altitude }) dataModel.SensorSettings

        Loadable.Data { dataModel with SensorSettings = newSensorSettings }, Cmd.none
    | UpdateWetLimit limit, Loadable.Data dataModel ->
        let newSettings =
            match dataModel.SensorSettings with
            | SensorSettings.Soil soilSettings -> SensorSettings.Soil { soilSettings with WetLimit = limit }
            | _ -> dataModel.SensorSettings

        Loadable.Data { dataModel with SensorSettings = newSettings }, Cmd.none
    | UpdateDryLimit limit, Loadable.Data dataModel ->
        let newSettings =
            match dataModel.SensorSettings with
            | SensorSettings.Soil soilSettings -> SensorSettings.Soil { soilSettings with DryLimit = limit }
            | _ -> dataModel.SensorSettings

        Loadable.Data { dataModel with SensorSettings = newSettings }, Cmd.none
    | _, _ -> model, Cmd.none

let private locationSuccessCallback dispatch (location: Position) =
    UserDefinedMapSensorPropertiesMsg.DeviceLocationReceived location
    |> UserDefinedMapSensorProperties
    |> dispatch

let private locationErrorCallback dispatch (error: PositionError) =
    UserDefinedMapSensorPropertiesMsg.DeviceLocationFailed error
    |> UserDefinedMapSensorProperties
    |> dispatch

let private createSaveRequestData
    (id: int)
    (settings: SensorSettings)
    (altitude: float option)
    (location: Location)
    : UpdatedMapSensorSettingsDto =
    match settings with
    | SensorSettings.Base baseSettings ->
        UpdatedMapSensorSettingsDto.Base {
            Id = id
            Name = baseSettings.Name
            Location = location
            Altitude = altitude
        }
    | SensorSettings.Soil soilSettings ->
        let baseSettings = {
            Id = id
            Name = soilSettings.BaseSettings.Name
            Location = location
            Altitude = altitude
        }

        UpdatedMapSensorSettingsDto.Soil {
            WetLimit = soilSettings.WetLimit
            DryLimit = soilSettings.DryLimit
            BaseSettings = baseSettings
        }


let private createSaveButton dispatch (model: DataModel) : ReactElement =
    let baseSettings = getBaseSettings model.SensorSettings

    let maybeRequestData =
        Option.map2 Location.create baseSettings.Longitude baseSettings.Latitude
        |> Option.flatten
        |> Option.map (createSaveRequestData model.Id model.SensorSettings baseSettings.Altitude)

    let buttonOptions = [
        Button.Color Color.IsSuccess
        Button.IsLoading model.UpdateRequestRunning

        match maybeRequestData with
        | Some requestData ->
            Button.OnClick(fun event ->
                event.preventDefault ()

                UpdateMapSensorProperties requestData
                |> UserDefinedMapSensorProperties
                |> dispatch
            )
        | None -> Button.Disabled true
    ]


    Button.button buttonOptions [ str "Einstellungen speichern" ]

let private createNameField dispatch name =
    Field.div [] [
        Label.label [] [ str "Name" ]
        Control.div [] [
            Input.text [
                Input.Placeholder "MS-TL0815"
                name |> Option.defaultValue "" |> Input.Value
                Input.OnChange(fun event ->
                    event.Value
                    |> String.toOption
                    |> UpdateName
                    |> UserDefinedMapSensorProperties
                    |> dispatch
                )
            ]
        ]
    ]

let private createLatitudeField dispatch latitude =
    Field.div [] [
        Label.label [] [ str "Breitengrad" ]
        Control.div [] [
            Input.number [
                Input.Placeholder "47.155999194351715"
                latitude |> Inputs.optionalDoubleToString |> Input.Value
                Input.OnChange(fun event ->
                    event.Value
                    |> Inputs.toDoubleOption
                    |> UpdateLatitude
                    |> UserDefinedMapSensorProperties
                    |> dispatch
                )
            ]
        ]
    ]

let private createLongitudeField dispatch longitude =
    Field.div [] [
        Label.label [] [ str "Längengrad" ]
        Control.div [] [
            Input.number [
                Input.Placeholder "15.649259567260742"
                longitude |> Inputs.optionalDoubleToString |> Input.Value
                Input.OnChange(fun event ->
                    event.Value
                    |> Inputs.toDoubleOption
                    |> UpdateLongitude
                    |> UserDefinedMapSensorProperties
                    |> dispatch
                )
            ]
        ]
    ]

let private createAltitudeField dispatch altitude =
    Field.div [] [
        Label.label [] [ str "Höhe über Meeresspiegel [m]" ]
        Control.div [] [
            Input.number [
                Input.Placeholder "400"
                altitude |> Inputs.optionalDoubleToString |> Input.Value
                Input.OnChange(fun event ->
                    event.Value
                    |> Inputs.toDoubleOption
                    |> UpdateAltitude
                    |> UserDefinedMapSensorProperties
                    |> dispatch
                )
            ]
        ]
    ]

let private createDeviceLocationButton dispatch locationUpdateRunning =
    Field.div [
        Field.Props [
            Style [ BorderBottom "1px solid black"; Padding "5px" ]
        ]
    ] [
        Control.div [] [
            Button.button [
                Button.IsLoading locationUpdateRunning
                Button.Color Color.IsLink
                match navigator.geolocation with
                | Some geolocation ->
                    Button.OnClick(fun event ->
                        event.preventDefault ()
                        dispatch (UserDefinedMapSensorProperties DeviceLocationRequested)

                        DeviceLocation.request
                            (locationSuccessCallback dispatch)
                            (locationErrorCallback dispatch)
                            geolocation
                    )
                | None -> Button.Disabled true
            ] [ str "Aktuellen Standort übernehmen" ]
        ]
    ]

let private createBaseSettingFields dispatch locationUpdateRunning (settings: BaseSettings) : ReactElement list = [
    createNameField dispatch settings.Name
    Field.div [ Field.IsHorizontal ] [
        Field.body [] [
            createLatitudeField dispatch settings.Latitude
            createLongitudeField dispatch settings.Longitude
        ]
    ]

    createAltitudeField dispatch settings.Altitude

    createDeviceLocationButton dispatch locationUpdateRunning
]

let private createLimitField (model: DataModel) (limit: double option) (label: string) onClick =
    Field.div [] [
        Label.label [] [ str label ]
        Control.div [] [
            Input.number [
                limit |> Inputs.optionalDoubleToString |> Input.Value
                Input.OnChange(fun event -> event.Value |> Inputs.toDoubleOption |> onClick)
            ]
        ]
    ]

let private soilFormView dispatch (model: DataModel) (soilSettings: SoilSensorSettings) =
    form [] [
        yield! createBaseSettingFields dispatch model.DeviceLocationUpdateRunning soilSettings.BaseSettings

        Field.div [ Field.IsHorizontal ] [
            Field.body [] [
                createLimitField
                    model
                    soilSettings.DryLimit
                    "Grenze Trocken (in Prozent)"
                    (UpdateDryLimit >> UserDefinedMapSensorProperties >> dispatch)
                createLimitField
                    model
                    soilSettings.WetLimit
                    "Grenze Nass (in Prozent)"
                    (UpdateWetLimit >> UserDefinedMapSensorProperties >> dispatch)
            ]
        ]

        Field.div [] [
            Control.div [] [ createSaveButton dispatch model ]
        ]
    ]

let private baseFormView dispatch (model: DataModel) (baseSettings: BaseSettings) =
    form [] [
        yield! createBaseSettingFields dispatch model.DeviceLocationUpdateRunning baseSettings

        Field.div [] [
            Control.div [] [ createSaveButton dispatch model ]
        ]
    ]

let formView dispatch (model: DataModel) =
    match model.SensorSettings with
    | SensorSettings.Base baseSettings -> baseFormView dispatch model baseSettings
    | SensorSettings.Soil soilSettings -> soilFormView dispatch model soilSettings

let dataView dispatch (data: DataModel) =
    let formContent = formView dispatch data

    Container.container [ Container.isFullWidth ] [
        Heading.h1 [] [ str "Sensor-Einstellungen" ]
        formContent
    ]

let view (model: Model) dispatch = Loadable.view (dataView dispatch) model