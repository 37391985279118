module Client.Page.UserList

open System
open Client.Domain
open Client.Infrastructure
open Client.InfrastructureTypes
open Elmish
open Fulma
open Shared
open Client
open Fable.React
open Client.Api
open Client.Msg
open Shared.Dto.Dto
open Shared.Dto.Page.UserList
open Shared.Dto.User
open Shared.Infrastructure
open Thoth.Elmish

type Data = {
    Users: IdValue<FullUserDto> list
    UserModal: Forms.User.Model option
    AddPackageModal: Forms.AddPackage.Model option
    Session: UserSession
}

type Model = Loadable<Data, UserSession>

let init (userSession: UserSession) =
    let request = {
        SessionKey = userSession.SessionKey
        Data = ()
    }

    let cmd =
        Cmd.OfAsync.perform api.getAllFullUsers request (UserListMsg.UsersLoaded >> UserList)

    Loadable.Loading userSession, cmd

let update (msg: UserListMsg) (model: Model) =
    match msg, model with
    | UsersLoaded maybeUserList, Loadable.Loading session ->
        match maybeUserList with
        | Result.Ok userList ->
            Loadable.Data {
                Users = userList
                Session = session
                UserModal = None
                AddPackageModal = None
            },
            Cmd.none
        | Result.Error message ->
            let errorMessage =
                match message with
                | AuthErr Unauthorized -> "Sie dürfen die notwendigen Daten nicht laden"
                | AuthErr Unauthenticated -> "Sie sind nicht eingeloggt. Bitte laden Sie die Seite neu"
                | CustomErr error -> error

            let cmd = Toast.create errorMessage |> Toast.error

            model, cmd
    | OpenModal user, Loadable.Data data -> Loadable.Data { data with UserModal = Some(Forms.User.init user) }, Cmd.none
    | UserListMsg.FormMsg formMsg, Loadable.Data data ->
        match data.UserModal with
        | Some modal ->
            let modal, cmd, formResult = Forms.User.update formMsg modal

            match formResult with
            | Forms.User.FormResult.Noop -> Loadable.Data { data with UserModal = Some modal }, cmd
            | Forms.User.FormResult.CloseModal -> Loadable.Data { data with UserModal = None }, cmd
            | Forms.User.FormResult.CloseAndRefresh -> init data.Session |> Cmds.batch cmd
        | None -> model, Cmd.none
    | OpenAddPackageModal userId, Loadable.Data data ->
        Loadable.Data {
            data with
                AddPackageModal =
                    Some(
                        Forms.AddPackage.init userId [
                            Package.BaseAccess
                            Package.AdditionalSensor
                            Package.History
                            Package.Alerts
                        ]
                        |> Loadable.Data
                    )
        },
        Cmd.none
    | AddPackage addPackageMsg, Loadable.Data data ->
        match data.AddPackageModal with
        | Some modal ->
            let modal, cmd, formResult = Forms.AddPackage.update addPackageMsg modal

            match formResult with
            | Forms.AddPackage.FormResult.Noop -> Loadable.Data { data with AddPackageModal = Some modal }, cmd
            | Forms.AddPackage.FormResult.CloseModal -> Loadable.Data { data with AddPackageModal = None }, cmd
            | Forms.AddPackage.FormResult.CloseAndRefresh -> init data.Session |> Cmds.batch cmd
        | None -> model, Cmd.none
    | DeleteUser id, _ -> model, Cmd.OfAsync.perform api.deleteUser id (UserDeleted >> UserList)
    | UserDeleted success, Loadable.Data data ->
        if success then
            let toastCmd = Toast.create "Benutzer erfolgreich gelöscht" |> Toast.success

            init data.Session |> Cmds.batch toastCmd
        else
            let toastCmd = Toast.create "Fehler beim Löschen des Benutzers" |> Toast.error

            model, toastCmd
    | _, _ -> model, Cmd.none


let createNewUserButton dispatch =
    Button.button [
        Button.Color IsLink
        Button.OnClick(fun _ -> dispatch (UserList <| UserListMsg.OpenModal None))
    ] [ str "Neuen Benutzer erstellen" ]

let private getRoleDataString (user: FullUserDto) : string =
    match user with
    | FullUserDto.Admin _ -> ""
    | FullUserDto.RegularUser _ ->
        user
        |> getPackages
        |> List.map (fun purchased -> purchased.Value.Package |> getPackageCharacter)
        |> List.sort
        |> fun chars -> String.Join(", ", chars)
        |> sprintf "Pakete: %s"
    | FullUserDto.TestUser testUser -> testUser.ValidUntil |> DateTime.toShortString |> sprintf "Gültig bis: %s"

let private userIsDisabled (user: FullUserDto) : bool =
    match user with
    | FullUserDto.Admin _
    | FullUserDto.RegularUser _ -> false
    | FullUserDto.TestUser testUser -> if testUser.ValidUntil > DateTime.Now then false else true

let userToRow dispatch (index: int) (user: IdValue<FullUserDto>) =
    let customerNumber =
        match user.Value with
        | FullUserDto.RegularUser regular -> regular.CustomerNumber
        | _ -> None

    tr [
        classList [ ("user_disabled", userIsDisabled user.Value) ]
    ] [
        td [] [ Table.rowIndexString index ]
        td [] [ str (getLastName user.Value) ]
        td [] [ str (getFirstName user.Value) ]
        td [] [ str (getMail user.Value) ]
        td [] [ str (userTypeToString user.Value) ]
        td [] [
            str (
                getLastLogin user.Value
                |> Option.map (fun dt -> dt.LocalDateTime)
                |> Option.map DateTime.toString
                |> Option.defaultValue "Noch nie"
            )
        ]
        td [] [ user.Value |> getRoleDataString |> str ]
        td [] [
            customerNumber |> Option.defaultValue "" |> str
        ]
        td [] [
            Button.button [
                Button.OnClick(fun _ -> dispatch (UserListMsg.OpenAddPackageModal user.Id |> UserList))
            ] [ str "Paket hinzufügen" ]
        ]
        td [] [
            Button.button [
                Button.OnClick(fun _ -> dispatch (UserListMsg.OpenModal(Some user) |> UserList))
            ] [ str "Bearbeiten" ]
        ]
        td [] [
            Button.button [
                Button.Color IsDanger
                Button.OnClick(fun _ -> dispatch (UserListMsg.DeleteUser user.Id |> UserList))
            ] [ str "Löschen" ]
        ]
    ]

let userListToTable dispatch (users: IdValue<FullUserDto> list) =
    Table.table [
        Table.IsBordered
        Table.IsFullWidth
        Table.IsStriped
    ] [
        thead [] [
            tr [] [
                th [] [ str "#" ]
                th [] [ str "Nachname" ]
                th [] [ str "Vorname" ]
                th [] [ str "Email Adresse" ]
                th [] [ str "Benutzer Typ" ]
                th [] [ str "Letzer Login" ]
                th [] [ str "Rolle-Daten" ]
                th [] [ str "SevDesk Kundennr." ]
                th [] []
                th [] []
                th [] []
            ]
        ]
        tbody [] (List.mapi (userToRow dispatch) users)
    ]

let tableHeader dispatch =
    Level.level [] [
        Level.left [] []
        Level.right [] [
            Level.item [] [
                Field.div [] [
                    Control.div [] [ createNewUserButton dispatch ]
                ]
            ]
        ]
    ]

let private dataView dispatch (data: Data) =
    let table = userListToTable dispatch data.Users

    Container.container [ Container.IsFluid ] [
        Heading.h1 [] [ str "Benutzer Liste" ]
        tableHeader dispatch
        Table.scrollableTable table

        match data.UserModal with
        | Some modal -> Forms.User.view dispatch modal
        | None -> ()

        match data.AddPackageModal with
        | Some modal -> Forms.AddPackage.view dispatch modal
        | None -> ()
    ]


let view (model: Model) dispatch = Loadable.view (dataView dispatch) model