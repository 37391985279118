module Client.Page.MyAlerts

open Client
open Client.Components
open Client.Domain
open Client.Forms
open Client.DomainTypes

open Client.InfrastructureTypes
open Client.InfrastructureTypes.Form
open Client.Msg
open Client.Api
open Elmish
open Fable.FontAwesome
open Fable.React.Props
open Fulma
open Fulma.Extensions.Wikiki
open Microsoft.FSharp.Core
open Shared
open Shared.Dto
open Shared.Infrastructure
open Fable.React
open Shared.Dto.Dto
open Shared.DtoTypes
open Thoth.Elmish

type DataModel = {
    Session: UserSession
    ContactInfos: AlertDto.ContactInfo list
    Alerts: AlertDto.RunningAlert list
    Sensors: IdValue<MapSensorDto> list
    ContactInfoForm: ContactInfo.Model option
    AlertForm: AlertForm.Model option
    ContactInfoDeleteModal: AlertDto.ContactInfo option
    AlertDeleteModal: AlertDto.RunningAlert option
    BuyAlertsFormModal: BuyAlertPackagesForm.Model option
    AlertStartedModal: unit option
    PurchasedAlertsCount: AlertDto.PurchasedAlertCount
}

type LoadingModel = {
    Session: UserSession
    ContactInfos: AlertDto.ContactInfo list option
    Alerts: AlertDto.RunningAlert list option
    Sensors: IdValue<MapSensorDto> list option
    PurchasedAlertCounts: AlertDto.PurchasedAlertCount option
}

type Model = Loadable<DataModel, LoadingModel>

let init (session: UserSession) =
    let requestData = {
        SessionKey = session.SessionKey
        Data = ()
    }

    let cmd =
        Cmd.batch [
            Cmd.OfAsync.perform api.getMyAlerts requestData (AlertsReceived >> MyAlerts)
            Cmd.OfAsync.perform api.getMyContactInfos requestData (ContactInfosReceived >> MyAlerts)
            Cmd.OfAsync.perform api.getMyAirTemperatureMapSensors requestData (SensorsReceived >> MyAlerts)
            Cmd.OfAsync.perform api.getPurchasedAlertCounts requestData (PurchasedAlertCountsReceived >> MyAlerts)
        ]

    Loadable.Loading {
        Session = session
        ContactInfos = None
        Alerts = None
        Sensors = None
        PurchasedAlertCounts = None
    },
    cmd
(*Loadable.Error "Die Frostbewarnung ist aktuell deaktiviert und wird Anfang nächsten Jahres wieder aktiviert",
    Cmd.none*)

let private createDataModel (loadingModel: LoadingModel) : Model option =
    (fun contactInfos alerts sensors purchasedCounts ->
        Loadable.Data {
            Session = loadingModel.Session
            ContactInfos = contactInfos
            Alerts = alerts
            Sensors = sensors
            ContactInfoForm = None
            AlertForm = None
            ContactInfoDeleteModal = None
            AlertDeleteModal = None
            BuyAlertsFormModal = None
            AlertStartedModal = None
            PurchasedAlertsCount = purchasedCounts
        }
    )
    |> Some
    |> Option.apply loadingModel.ContactInfos
    |> Option.apply loadingModel.Alerts
    |> Option.apply loadingModel.Sensors
    |> Option.apply loadingModel.PurchasedAlertCounts

let tryDataModel (loadingModel: LoadingModel) =
    let newModel =
        createDataModel loadingModel
        |> Option.defaultValue (Loadable.Loading loadingModel)

    newModel, Cmd.none

let update (msg: MyAlertsMsg) (model: Model) =
    match msg, model with
    | SensorsReceived sensorsResult, Loadable.Loading loadingModel ->
        match sensorsResult with
        | Ok sensors ->
            let newLoadingModel = { loadingModel with Sensors = Some sensors }

            tryDataModel newLoadingModel
        | Error _ ->
            let toastCmd = Toast.create "Fehler beim Laden der Bewarnungen" |> Toast.error

            model, toastCmd
    | AlertsReceived alertsResult, Loadable.Loading loadingModel ->
        match alertsResult with
        | Ok alerts ->
            let newLoadingModel = { loadingModel with Alerts = Some alerts }

            tryDataModel newLoadingModel
        | Error _ ->
            let toastCmd = Toast.create "Fehler beim Laden der Bewarnungen" |> Toast.error

            model, toastCmd
    | ContactInfosReceived contactInfosResult, Loadable.Loading loadingModel ->
        match contactInfosResult with
        | Ok contactInfos ->
            let newLoadingModel = { loadingModel with ContactInfos = Some contactInfos }

            tryDataModel newLoadingModel
        | Error _ ->
            let toastCmd = Toast.create "Fehler beim Laden der Bewarnungen" |> Toast.error

            model, toastCmd
    | ContactInfosReceived contactInfosResult, Loadable.Data dataModel ->
        match contactInfosResult with
        | Ok contactInfos ->
            let newModel = { dataModel with ContactInfos = contactInfos }

            Loadable.Data newModel, Cmd.none
        | Error _ ->
            let toastCmd = Toast.create "Fehler beim Laden der Kontaktdaten" |> Toast.error

            model, toastCmd
    | PurchasedAlertCountsReceived countsResult, Loadable.Loading loadingModel ->
        match countsResult with
        | Ok counts ->
            let newLoadingModel = { loadingModel with PurchasedAlertCounts = Some counts }

            tryDataModel newLoadingModel
        | Error _ ->
            let toastCmd = Toast.create "Fehler beim Laden der Bewarnungen" |> Toast.error

            model, toastCmd
    | PurchasedAlertCountsReceived countsResult, Loadable.Data dataModel ->
        match countsResult with
        | Ok counts ->
            let newModel = { dataModel with PurchasedAlertsCount = counts }

            Loadable.Data newModel, Cmd.none
        | Error _ ->
            let toastCmd = Toast.create "Fehler beim Laden der Bewarnungen" |> Toast.error

            Loadable.Error "Fehler beim Laden der Daten", toastCmd
    | ContactInfoForm formMsg, Loadable.Data dataModel ->
        match dataModel.ContactInfoForm with
        | Some formModel ->
            let formModel, cmd, formResult = ContactInfo.update formMsg formModel

            match formResult with
            | ContactInfo.Noop ->
                Loadable.Data { dataModel with ContactInfoForm = Some formModel },
                Cmd.map (ContactInfoForm >> MyAlerts) cmd
            | ContactInfo.CloseModal ->
                Loadable.Data { dataModel with ContactInfoForm = None }, Cmd.map (ContactInfoForm >> MyAlerts) cmd
            | ContactInfo.CloseAndRefresh ->
                let requestData = {
                    SessionKey = dataModel.Session.SessionKey
                    Data = ()
                }

                let cmds =
                    Cmd.batch [
                        Cmd.map (ContactInfoForm >> MyAlerts) cmd
                        Cmd.OfAsync.perform api.getMyContactInfos requestData (ContactInfosReceived >> MyAlerts)
                    ]

                Loadable.Data { dataModel with ContactInfoForm = None }, cmds
        | None -> model, Cmd.none
    | OpenContactInfoForm, Loadable.Data dataModel ->
        let formModel = ContactInfo.init dataModel.Session

        Loadable.Data { dataModel with ContactInfoForm = Some formModel }, Cmd.none
    | OpenContactInfoDeleteModal contactInfo, Loadable.Data dataModel ->
        Loadable.Data { dataModel with ContactInfoDeleteModal = Some contactInfo }, Cmd.none
    | CloseContactInfoDeleteModal, Loadable.Data dataModel ->
        Loadable.Data { dataModel with ContactInfoDeleteModal = None }, Cmd.none
    | DeleteContactInfo id, Loadable.Data dataModel ->
        let request = {
            SessionKey = dataModel.Session.SessionKey
            Data = id
        }

        Loadable.Data { dataModel with ContactInfoDeleteModal = None },
        Cmd.OfAsync.perform api.deleteContactInfo request (ContactInfoDeleted >> MyAlerts)
    | ContactInfoDeleted result, Loadable.Data dataModel ->
        match result with
        | Ok _ ->
            let toastCmd = Toast.create "Löschen war erfolgreich" |> Toast.success

            let request = {
                SessionKey = dataModel.Session.SessionKey
                Data = ()
            }

            let reloadCmd =
                Cmd.OfAsync.perform api.getMyContactInfos request (ContactInfosReceived >> MyAlerts)

            let commands = Cmd.batch [ toastCmd; reloadCmd ]

            model, commands
        | Error _ ->
            let toastCmd = Toast.create "Beim Löschen ist ein Fehler aufgetreten" |> Toast.error

            model, toastCmd
    | OpenAlertDeleteModal runningAlert, Loadable.Data dataModel ->
        Loadable.Data { dataModel with AlertDeleteModal = Some runningAlert }, Cmd.none
    | CloseAlertDeleteModal, Loadable.Data dataModel ->
        Loadable.Data { dataModel with AlertDeleteModal = None }, Cmd.none
    | DeleteAlert id, Loadable.Data dataModel ->
        let request = {
            SessionKey = dataModel.Session.SessionKey
            Data = id
        }

        Loadable.Data { dataModel with AlertDeleteModal = None },
        Cmd.OfAsync.perform api.deleteAlert request (AlertDeleted >> MyAlerts)
    | AlertDeleted result, Loadable.Data dataModel ->
        match result with
        | Ok _ ->
            let toastCmd = Toast.create "Löschen war erfolgreich" |> Toast.success

            let request = {
                SessionKey = dataModel.Session.SessionKey
                Data = ()
            }

            let reloadCmd =
                Cmd.OfAsync.perform api.getMyAlerts request (AlertsReceived >> MyAlerts)

            let commands = Cmd.batch [ toastCmd; reloadCmd ]

            model, commands
        | Error _ ->
            let toastCmd = Toast.create "Beim Löschen ist ein Fehler aufgetreten" |> Toast.error

            model, toastCmd
    | AlertsReceived alertsResult, Loadable.Data dataModel ->
        match alertsResult with
        | Ok alerts ->
            let newModel = { dataModel with Alerts = alerts }

            Loadable.Data newModel, Cmd.none
        | Error _ ->
            let toastCmd = Toast.create "Fehler beim Laden der Bewarnungen" |> Toast.error

            model, toastCmd
    | AlertForm formMsg, Loadable.Data dataModel ->
        match dataModel.AlertForm with
        | Some formModel ->
            let formModel, cmd, formResult = AlertForm.update formMsg formModel

            match formResult with
            | AlertForm.Noop ->
                Loadable.Data { dataModel with AlertForm = Some formModel }, Cmd.map (AlertForm >> MyAlerts) cmd
            | AlertForm.CloseModal ->
                Loadable.Data { dataModel with AlertForm = None }, Cmd.map (AlertForm >> MyAlerts) cmd
            | AlertForm.CloseAndRefresh ->
                Loadable.Data {
                    dataModel with
                        AlertForm = None
                        AlertStartedModal = Some()
                },
                Cmd.map (AlertForm >> MyAlerts) cmd
        | None -> model, Cmd.none
    | CloseAlertStartedModal, Loadable.Data dataModel ->
        let requestData = {
            SessionKey = dataModel.Session.SessionKey
            Data = ()
        }

        Loadable.Data { dataModel with AlertStartedModal = None },
        Cmd.OfAsync.perform api.getMyAlerts requestData (AlertsReceived >> MyAlerts)

    | OpenAlertForm, Loadable.Data dataModel ->
        Loadable.Data {
            dataModel with
                AlertForm =
                    Some
                    <| AlertForm.init dataModel.Session dataModel.Sensors dataModel.ContactInfos
        },
        Cmd.none
    | OpenBuyForm, Loadable.Data dataModel ->
        let formModel = BuyAlertPackagesForm.init dataModel.Session.SessionKey

        Loadable.Data { dataModel with BuyAlertsFormModal = Some formModel }, Cmd.none
    | BuyPackagesForm msg, Loadable.Data dataModel ->
        match dataModel.BuyAlertsFormModal with
        | Some modal ->
            let newFormModel, cmd, formResult = BuyAlertPackagesForm.update msg modal

            match formResult with
            | Close -> Loadable.Data { dataModel with BuyAlertsFormModal = None }, Cmd.none
            | CloseAndRefresh -> init dataModel.Session
            | FormResult.Noop ->
                Loadable.Data { dataModel with BuyAlertsFormModal = Some newFormModel }, Cmd.map MyAlerts cmd
        | None -> model, Cmd.none
    | _ -> model, Cmd.none

let contactInfoInUse (alerts: AlertDto.RunningAlert list) (contactInfo: int) =
    alerts |> List.exists (fun alert -> alert.Alert.ContactInfoId = contactInfo)

let private createDeleteContactInfoButton contactInfo inUse dispatch =
    let dynamicButtonOptions =
        if inUse then
            [
                Button.Disabled true
                Button.CustomClass Tooltip.ClassName
                Button.Props [ Tooltip.dataTooltip "Ist gerade in Verwendung" ]
            ]
        else
            []

    let staticButtonOptions = [
        Button.Color IsDanger
        Button.OnClick(fun _ -> OpenContactInfoDeleteModal contactInfo |> dispatch)
    ]

    let buttonOptions = List.concat [ dynamicButtonOptions; staticButtonOptions ]

    Button.button buttonOptions [
        Icon.icon [] [ Fa.i [ Fa.Solid.Trash ] [] ]
        span [] [ str "Löschen" ]
    ]


let private createContactInfoRow dispatch inUse (contactInfo: AlertDto.ContactInfo) =
    Columns.columns [ Columns.IsVCentered; Columns.bottomBorder ] [
        Column.column [] [
            span [ Html.label ] [ str "Name:" ]
            span [ Html.bold ] [ str contactInfo.Name ]
        ]
        Column.column [] [
            span [ Html.label ] [
                str (AlertDto.contactInfoTypeToString contactInfo.Data)
            ]
            span [ Html.bold ] [
                str (AlertDto.contactInfoToString contactInfo.Data)
            ]
        ]
        Column.column [
            Column.Modifiers [
                Modifier.TextAlignment(Screen.All, TextAlignment.Right)
            ]
        ] [
            createDeleteContactInfoButton contactInfo inUse dispatch
        ]
    ]

let private createAddButton onAddClick =
    Button.button [ Button.Color IsLink; Button.OnClick onAddClick ] [
        Icon.icon [] [ Fa.i [ Fa.Solid.Plus ] [] ]
        span [] [ str "Hinzufügen" ]
    ]


let private createAddButtonRow currentCount maybeMaxCount onAddClick =
    let isAllowed =
        match maybeMaxCount with
        | Some maxCount -> maxCount - currentCount > 0
        | None -> true

    let content =
        if isAllowed then
            let buttonOptions = [ Button.Color IsLink; Button.OnClick onAddClick ]


            Level.item [] [
                Button.button buttonOptions [
                    Icon.icon [] [ Fa.i [ Fa.Solid.Plus ] [] ]
                    span [] [ str "Hinzufügen" ]
                ]
            ]
        else
            Level.item [] [
                p [] [ str "Keine weiteren Einträge verfügbar" ]
            ]

    Level.level [] [ Level.left [] []; Level.right [] [ content ] ]


let boxHeadlineWithCount headline usedCount maxCount =
    let usedText = sprintf "%d von %s in Verwendung" usedCount maxCount

    Level.level [] [
        Level.left [] [
            Level.item [] [ Heading.p [ Heading.Is3 ] [ str headline ] ]
        ]
        Level.right [] [ Level.item [] [ span [] [ str usedText ] ] ]
    ]

let private contactInfoBox dispatch (model: DataModel) =
    let rows =
        model.ContactInfos
        |> List.map (fun contactInfo -> contactInfo, contactInfoInUse model.Alerts contactInfo.Id)
        |> List.map (fun (contactInfo, inUse) -> createContactInfoRow dispatch inUse contactInfo)

    let buttonRow =
        createAddButton (fun _ -> dispatch OpenContactInfoForm)
        |> (fun button ->
            Level.level [] [
                Level.left [] []
                Level.right [] [ Level.item [] [ button ] ]
            ]
        )

    let headingContent = str "2. Kontakt Daten hinzufügen"

    let content = [ yield! rows; buttonRow ]

    Box.wrapWithHeading headingContent content

let private createDeleteAlertButton alert dispatch =
    Button.button [
        Button.Color IsDanger
        Button.OnClick(fun _ -> OpenAlertDeleteModal alert |> dispatch)
    ] [
        Icon.icon [] [ Fa.i [ Fa.Solid.Trash ] [] ]
        span [] [ str "Löschen" ]
    ]

let private createSensorLink dispatch (sensor: IdValue<MapSensorDto>) =
    a [
        OnClick(fun _ -> dispatch (Route.MySensSensor sensor.Id |> GoToRoute |> Global))
    ] [ str (MapSensor.getBaseData sensor.Value).Name ]

let private createAlertsRowIcon (alert: AlertDto.RunningAlert) =
    match alert.Alert.PhoneAlertType with
    | AlertDto.Call ->
        Icon.icon [
            Icon.CustomClass Tooltip.ClassName
            Icon.Props [ Tooltip.dataTooltip "Anruf" ]
        ] [ Fa.i [ Fa.Solid.Phone ] [] ]
    | AlertDto.SMS ->
        Icon.icon [
            Icon.CustomClass Tooltip.ClassName
            Icon.Props [ Tooltip.dataTooltip "SMS" ]
        ] [ Fa.i [ Fa.Solid.Sms ] [] ]

let private createAlertsRow
    dispatch
    (sensors: IdValue<MapSensorDto> list)
    (contactInfos: AlertDto.ContactInfo list)
    (alert: AlertDto.RunningAlert)
    =
    let sensorName =
        sensors
        |> List.tryFind (fun sensor -> sensor.Id = alert.Alert.SensorId)
        |> Option.map (createSensorLink dispatch)
        |> Option.defaultValue (str "Unbekannter Sensor?")

    let contactInfoName =
        contactInfos
        |> List.tryFind (fun info -> info.Id = alert.Alert.ContactInfoId)
        |> Option.map (fun info -> info.Name)
        |> Option.defaultValue "Unbekannte Kontakt Daten"

    let stateClass =
        match alert.State with
        | AlertDto.Ready _ -> "alert_ready"
        | AlertDto.Alerted _ -> "alert_alerted"
        | AlertDto.Outdated _ -> "alert_outdated"

    let stateText = sprintf "%s" (AlertDto.stateToString alert.State)

    let temperatureString =
        sprintf
            "Grenze: %.1f ˚C (Aktuell: %s)"
            alert.Alert.Threshold
            (alert.LastTemperature
             |> Option.map (fun value -> sprintf "%.1f ˚C" value.Value)
             |> Option.defaultValue "Warte auf neue Daten")

    Columns.columns [ Columns.IsVCentered; Columns.bottomBorder ] [
        Column.column [ Column.Width(Screen.All, Column.IsNarrow) ] [ sensorName ]
        Column.column [ Column.Width(Screen.All, Column.IsNarrow) ] [
            createAlertsRowIcon alert
            span [] [ str contactInfoName ]
        ]
        Column.column [ Column.Width(Screen.All, Column.Is2) ] [
            str (AlertDto.thresholdTypeToString alert.Alert.TemperatureType)
        ]
        Column.column [] [ str temperatureString ]
        Column.column [
            Column.CustomClass stateClass
            Column.Width(Screen.All, Column.IsNarrow)
        ] [ str stateText ]
        Column.column [
            Column.Width(Screen.All, Column.IsNarrow)
            Column.Modifiers [
                Modifier.TextAlignment(Screen.All, TextAlignment.Right)
            ]
        ] [
            createDeleteAlertButton alert (MyAlerts >> dispatch)
        ]
    ]

let private alertsBox dispatch (model: DataModel) =
    let rows =
        model.Alerts
        |> List.map (createAlertsRow dispatch model.Sensors model.ContactInfos)

    let alertsCount = List.length model.Alerts

    let maxCount =
        model.PurchasedAlertsCount.PurchasedAlertPackagesCount
        |> Option.map (fun count -> count * 3)

    let maxCountText = maxCount |> Option.map string |> Option.defaultValue "unendlich"

    let addButtonRow =
        createAddButtonRow alertsCount maxCount (fun _ -> dispatch (MyAlerts OpenAlertForm))

    let headline =
        boxHeadlineWithCount "3. Frostbewarnungen einrichten" alertsCount maxCountText

    let boxContent = [
        headline
        if not (List.isEmpty model.ContactInfos) then
            yield! rows
            addButtonRow
        else
            ()
    ]

    Box.box' [] boxContent

let deleteContactInfoModalConfig dispatch (contactInfo: AlertDto.ContactInfo) : ConfirmationModal.Configuration =
    let text =
        sprintf "Sollen die Kontaktdaten '%s' wirklich gelöscht werden?" contactInfo.Name

    {
        Headline = "Kontaktdaten löschen"
        Text = text
        OnClose = (fun _ -> dispatch CloseContactInfoDeleteModal)
        OnYes = (fun _ -> dispatch (DeleteContactInfo contactInfo.Id))
        OnNo = (fun _ -> dispatch CloseContactInfoDeleteModal)
    }

let deleteAlertModalConfig
    dispatch
    (sensors: IdValue<MapSensorDto> list)
    (alert: AlertDto.RunningAlert)
    : ConfirmationModal.Configuration =
    let sensorName =
        sensors
        |> List.tryFind (fun sensor -> sensor.Id = alert.Alert.SensorId)
        |> Option.map (fun sensor -> (MapSensor.getBaseData sensor.Value).Name)
        |> Option.defaultValue "Unbekannter Sensor"

    let text =
        sprintf "Sollen die Bewarnung für den Sensor '%s' wirklich gelöscht werden?" sensorName

    {
        Headline = "Bewarnung löschen"
        Text = text
        OnClose = (fun _ -> dispatch CloseAlertDeleteModal)
        OnYes = (fun _ -> dispatch (DeleteAlert alert.Alert.Id))
        OnNo = (fun _ -> dispatch CloseAlertDeleteModal)
    }


let private packagesText (packagesCount: int option) =
    match packagesCount with
    | None -> "Du hast unendlich viele Pakete zur Verfügung"
    | Some 0 -> "Du hast noch kein Frostbewarnungs-Paket gebucht"
    | Some 1 -> "Du hast ein Frostbewarnungs-Paket gebucht"
    | Some cnt -> sprintf "Du hast %d Frostbewarnungs Pakete gebucht" cnt

type BuyButtonState =
    | NotAllowed of string
    | Allowed

let private createBuyButton dispatch (state: BuyButtonState) =
    Button.button [
        Button.Color IsLink
        Button.OnClick(fun _ -> dispatch OpenBuyForm)
        Button.CustomClass Tooltip.ClassName
        match state with
        | NotAllowed reason ->
            Button.Props [ Tooltip.dataTooltip reason ]
            Button.Disabled true
        | Allowed -> ()
    ] [
        Icon.icon [] [ Fa.i [ Fa.Solid.Plus ] [] ]
        span [] [ str "Weitere Pakete bestellen" ]
    ]


let private packagesInfoBox dispatch (model: DataModel) =
    let allAlertsUsed =
        match model.PurchasedAlertsCount.PurchasedAlertPackagesCount with
        | None -> false
        | Some x -> x * 3 <= List.length model.Alerts

    let buyButtonState =
        match model.PurchasedAlertsCount.PurchasedAlertPackagesCount, allAlertsUsed with
        | None, _ -> NotAllowed "Du brauchst kein Bewarnungspaket"
        | _, true -> Allowed
        | _, false -> NotAllowed "Du hast noch freie Bewarnungen"

    let packagesText =
        packagesText model.PurchasedAlertsCount.PurchasedAlertPackagesCount

    let headingContent = str "1. Frostbewarnungs Pakete bestellen"

    let content =
        Level.level [] [
            Level.left [] [ Level.item [] [ str packagesText ] ]
            Level.right [] [
                Level.item [] [ createBuyButton dispatch buyButtonState ]
            ]
        ]

    Box.wrapWithHeading headingContent [ content ]

let private alertStartedModal dispatch =
    let closeModal = (fun _ -> dispatch MyAlertsMsg.CloseAlertStartedModal)

    let headline = "Frostbewarnung gestartet"

    Modal.modal [ Modal.IsActive true ] [
        Modal.background [
            GenericOption.Props [ DOMAttr.OnClick closeModal ]
        ] []
        Modal.Card.card [] [
            Modal.Card.head [] [
                Modal.Card.title [] [ str headline ]
                Delete.delete [ Delete.OnClick closeModal ] []
            ]
            Modal.Card.body [] [
                Content.content [] [
                    p [] [
                        str
                            """
Die Bewarnung wurde gestartet. Du solltest in den nächsten Minuten eine SMS oder einen Anruf
auf die ausgewählte Telefonnummer erhalten.
"""
                    ]
                    p [] [
                        str
                            """
Wenn dem nicht so ist, dann kontrolliere die Telefonnummer und erstelle die Bewarnung neu!
"""

                    ]
                ]
            ]
            Modal.Card.foot [] [
                Button.button [ Button.OnClick closeModal ] [ str "Ok" ]
            ]
        ]
    ]


let dataView dispatch (model: DataModel) =
    let myAlertsDispatch = (MyAlerts >> dispatch)

    let contactForm =
        model.ContactInfoForm
        |> Option.map (ContactInfo.view (ContactInfoForm >> myAlertsDispatch))

    let alertForm =
        model.AlertForm |> Option.map (AlertForm.view (AlertForm >> myAlertsDispatch))

    let deleteContactInfoModal =
        model.ContactInfoDeleteModal
        |> Option.map (deleteContactInfoModalConfig myAlertsDispatch >> ConfirmationModal.view false)

    let deleteAlertModal =
        model.AlertDeleteModal
        |> Option.map (
            deleteAlertModalConfig myAlertsDispatch model.Sensors
            >> ConfirmationModal.view false
        )

    let buyPackagesModal =
        model.BuyAlertsFormModal
        |> Option.map (BuyAlertPackagesForm.view (BuyPackagesForm >> myAlertsDispatch))

    let alertStartedModal =
        model.AlertStartedModal
        |> Option.map (fun _ -> alertStartedModal myAlertsDispatch)

    let containerContent = [
        packagesInfoBox myAlertsDispatch model
        match model.PurchasedAlertsCount.PurchasedAlertPackagesCount with
        | None -> contactInfoBox myAlertsDispatch model
        | Some x when x > 0 -> contactInfoBox myAlertsDispatch model
        | _ -> ()

        if not (List.isEmpty model.ContactInfos) then
            alertsBox dispatch model
        else
            ()
    ]

    [
        Some(Container.container [ Container.isFullWidth ] containerContent)

        contactForm
        alertForm
        deleteContactInfoModal
        deleteAlertModal
        buyPackagesModal
        alertStartedModal
    ]
    |> List.choose id

let view (model: Model) dispatch =
    Loadable.listView (dataView dispatch) model