namespace Client.Domain

open Client
open Client.DomainTypes
open Elmish
open Elmish.UrlParser

[<RequireQualifiedAccess>]
module Routing =
    let parseRoute: Parser<Route -> Route, Route> =
        oneOf [
            map Home top
            map PublicMap (s "public-map")
            map Login (s "login")
            map TtnSensorList (s "physical-sensor-list")
            map MySensSensorList (s "my-sens-sensor-list")
            map GatewayList (s "gateway-list")
            map UserList (s "user-list")
            map (SensorMap None) (s "sensor-map")
            map MySettings (s "my-settings")
            map PasswordLost (s "password-lost")
            map ResetPassword (s "reset-password" </> str)
            map CalculationConfiguration (s "calculation-configuration")
            map MySensSensor (s "mysens-sensor" </> i32)
            map AlertsList (s "alerts-list")
            map MyAlerts (s "my-alerts")
            map MySensorsData (s "my-sensors-data")
            map UserDefinedMapSensorProperties (s "map-sensor-properties" </> i32)
            map MyMapSensors (s "my-map-sensors")
            map RimPro (s "disease-models" </> s "rim-pro")
            map MyGateways (s "my-gateways")
            map ScabData (s "scab-data" </> i32)
            map MyScabStations (s "my-scab-stations")
            map MyUserGroups (s "my-user-groups")
            map MyPeronosporaStations (s "my-peronospora-stations")
            map PeronosporaData (s "peronospora-data" </> i32)
        ]

    let routeToUrl route =
        match route with
        | Home -> "/"
        | TtnSensorList -> "/physical-sensor-list"
        | Login -> "/login"
        | MySensSensorList -> "/my-sens-sensor-list"
        | GatewayList -> "/gateway-list"
        | UserList -> "/user-list"
        | SensorMap _ -> "/sensor-map"
        | PublicMap -> "/public-map"
        | MySettings -> "/my-settings"
        | PasswordLost -> "/password-lost"
        | CalculationConfiguration -> "/calculation-configuration"
        | ResetPassword token -> sprintf "/reset-password/%s" token
        | MySensSensor id -> sprintf "/mysens-sensor/%d" id
        | AlertsList -> "/alerts-list"
        | MyAlerts -> "/my-alerts"
        | MySensorsData -> "/my-sensors-data"
        | UserDefinedMapSensorProperties id -> sprintf "/map-sensor-properties/%d" id
        | MyMapSensors -> "/my-map-sensors"
        | RimPro -> "/disease-models/rim-pro"
        | MyGateways -> "/my-gateways"
        | NotFound -> failwith "NotFound Page doesn't have a route"
        | ScabData id -> sprintf "/scab-data/%d" id
        | MyScabStations -> "/my-scab-stations"
        | MyUserGroups -> "/my-user-groups"
        | MyPeronosporaStations -> "/my-peronospora-stations"
        | PeronosporaData id -> sprintf "/peronospora-data/%d" id

    let routeToAdditionalCommands route =
        match route with
        | SensorMap(Some location) -> Msg.MapMsg.MoveToLocation location |> Msg.Map |> Cmd.ofMsg
        | _ -> Cmd.none