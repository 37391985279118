module Client.Forms.MapSensor

open System
open Browser.Types
open Browser.Navigator
open Client
open Client.Components.Select
open Client.Domain
open Client.InfrastructureTypes
open Client.Msg
open Elmish
open Fulma
open Shared
open Shared.Dto
open Shared.Dto.Dto
open Fable.React
open Fable.React.Props
open Shared.Dto.User
open Shared.DtoTypes.PhysicalSensor
open Shared.Infrastructure
open Thoth.Elmish

type SensorData = {
    Id: int option
    Name: string option
    Latitude: double option
    Longitude: double option
    Altitude: float option
    IsPublic: bool
    State: MapSensorStateDto
    SensorType: SensorType
    TtnSensorSelected: ConfiguredPhysicalSensor option
    UserSelected: UserDto option
    Note: string option
    LastAssignmentDate: DateTime option
    StateSince: DateTimeOffset
}

type Model = {
    TtnSensors: ConfiguredPhysicalSensor list
    Sensor: SensorData
    Users: UserDto list
    RequestRunning: bool
    RetrieveCurrentLocationRunning: bool
}

let initNew (sensors: ConfiguredPhysicalSensor list) (users: UserDto list) (sensor: ConfiguredPhysicalSensor) =
    let sensorModel = {
        Id = None
        Name = Some sensor.BaseData.Name
        Latitude = Some 0.
        Longitude = Some 0.
        Altitude = None
        IsPublic = false
        State = MapSensorStateDto.Disabled
        SensorType = (sensorTypeFromPhysicalSensor sensor)
        TtnSensorSelected = Some sensor
        UserSelected = None
        Note = None
        LastAssignmentDate = None
        StateSince = DateTimeOffset.UtcNow
    }

    {
        Sensor = sensorModel
        TtnSensors = sensors
        RequestRunning = false
        RetrieveCurrentLocationRunning = false
        Users = users
    }

let initExisting (sensors: ConfiguredPhysicalSensor list) (users: UserDto list) (sensor: IdValue<MapSensorDto>) =
    let sensorModel =
        let baseSensorData = MapSensor.getBaseData sensor.Value

        let ttnSensor =
            Option.map
                (fun eui -> List.tryFind (fun ttnSensor -> ttnSensor.BaseData.DeviceEui = eui) sensors)
                baseSensorData.TtnSensorId
            |> Option.flatten

        let user =
            Option.map
                (fun userId -> List.tryFind (fun (user: UserDto) -> user.Id = userId) users)
                baseSensorData.UserId
            |> Option.flatten

        {
            Id = Some sensor.Id
            Name = Some baseSensorData.Name
            Latitude = Some baseSensorData.Location.Latitude
            Longitude = Some baseSensorData.Location.Longitude
            Altitude = baseSensorData.Altitude
            IsPublic = baseSensorData.IsPublic
            State = baseSensorData.State
            SensorType = MapSensor.toSensorType sensor.Value
            TtnSensorSelected = ttnSensor
            UserSelected = user
            Note = baseSensorData.Note
            LastAssignmentDate = baseSensorData.LastAssignmentDate
            StateSince = baseSensorData.StateSince
        }

    {
        Sensor = sensorModel
        TtnSensors = sensors
        RequestRunning = false
        RetrieveCurrentLocationRunning = false
        Users = users
    }

let private sensorTypeToString (type_: SensorType) =
    match type_ with
    | SensorType.Air -> "Luft"
    | SensorType.Soil -> "Boden"
    | SensorType.RainFall -> "Regenmenge"
    | SensorType.LeafletMoisture -> "Blattnässe"
    | SensorType.PH -> "PH"
    | SensorType.WindAverage -> "Wind Durchschnitt"
    | SensorType.WeatherStation -> "Wetterstation"

let private sensorTypeFromString (type_: string) =
    match type_ with
    | "Luft" -> SensorType.Air
    | "Boden" -> SensorType.Soil
    | "Regenmenge" -> SensorType.RainFall
    | "Blattnässe" -> SensorType.LeafletMoisture
    | "PH" -> SensorType.PH
    | "Wind Durchschnitt" -> SensorType.WindAverage
    | "Wetterstation" -> SensorType.WeatherStation
    | _ -> failwithf "Unknown Sensor Type '%s'" type_

let sensorTypeToOption (type_: SensorType) =
    let sensorType = sensorTypeToString type_

    option [ Value sensorType ] [ str sensorType ]

let sensorTypeSelect (isExisting: bool) (selected: SensorType) dispatch =
    let types = [
        SensorType.Air
        SensorType.Soil
        SensorType.RainFall
        SensorType.LeafletMoisture
        SensorType.PH
        SensorType.WindAverage
        SensorType.WeatherStation
    ]

    let options = List.map sensorTypeToOption types

    Select.select [ Select.IsFullWidth ] [
        select
            [
                DefaultValue(sensorTypeToString selected)
                Disabled isExisting
                OnChange(fun event ->
                    dispatch (
                        sensorTypeFromString event.Value
                        |> MapSensorListMsg.SelectedSensorTypeUpdated
                        |> MapSensorList
                    )
                )
            ]
            options
    ]


let physicalSensorToOption (sensor: ConfiguredPhysicalSensor) =
    option [ Value sensor.BaseData.DeviceEui ] [ str sensor.BaseData.Name ]

let physicalSensorSelect (selected: ConfiguredPhysicalSensor option) (list: ConfiguredPhysicalSensor list) dispatch =
    let options =
        option [ Value "" ] [ str "-" ] :: List.map physicalSensorToOption list

    let findSensor =
        fun selectedDeviceEui ->
            List.tryFind (fun (sensor: ConfiguredPhysicalSensor) -> sensor.BaseData.DeviceEui = selectedDeviceEui) list

    let selectedValue =
        Option.map (fun sensor -> sensor.BaseData.DeviceEui) selected
        |> Option.defaultValue ""

    Select.select [ Select.IsFullWidth ] [
        select
            [
                DefaultValue selectedValue
                OnChange(fun event ->
                    dispatch (
                        findSensor event.Value
                        |> MapSensorListMsg.SelectedSensorUpdated
                        |> MapSensorList
                    )
                )
            ]
            options
    ]

let private locationSuccessCallback dispatch (location: Position) =
    MapSensorListMsg.CurrentLocationReceived location |> MapSensorList |> dispatch

let private locationErrorCallback dispatch (error: PositionError) =
    MapSensorListMsg.CurrentLocationFailed error |> MapSensorList |> dispatch

let private deviceLocationOnClick dispatch geolocation =
    DeviceLocation.request (locationSuccessCallback dispatch) (locationErrorCallback dispatch) geolocation

let private sensorStateToString (state: MapSensorStateDto) =
    match state with
    | MapSensorStateDto.Active -> "Aktiv"
    | MapSensorStateDto.Disabled -> "Deaktiviert"
    | MapSensorStateDto.Defective -> "Defekt"

let private sensorStateFromString (state: string) =
    match state with
    | "Aktiv" -> MapSensorStateDto.Active
    | "Deaktiviert" -> MapSensorStateDto.Disabled
    | "Defekt" -> MapSensorStateDto.Defective
    | _ -> failwithf "Unknown Sensor State '%s'" state

let sensorStateToOption (state: MapSensorStateDto) =
    let sensorType = sensorStateToString state

    option [ Value sensorType ] [ str sensorType ]

let private stateSelect dispatch (selected: MapSensorStateDto) =
    let types = [
        MapSensorStateDto.Active
        MapSensorStateDto.Disabled
        MapSensorStateDto.Defective
    ]

    let options = List.map sensorStateToOption types

    Select.select [ Select.IsFullWidth ] [
        select
            [
                DefaultValue(sensorStateToString selected)
                OnChange(fun event ->
                    dispatch (
                        sensorStateFromString event.Value
                        |> MapSensorListMsg.SelectedStateUpdated
                        |> MapSensorList
                    )
                )
            ]
            options
    ]

let form dispatch (model: Model) =
    form [] [
        Field.div [] [
            Label.label [] [ str "Name" ]
            Control.div [] [
                Input.text [
                    Input.Placeholder "MS-TL0815"
                    model.Sensor.Name |> Option.defaultValue "" |> Input.Value
                    Input.OnChange(fun event ->
                        event.Value
                        |> String.toOption
                        |> MapSensorListMsg.SensorNameUpdated
                        |> MapSensorList
                        |> dispatch
                    )
                ]
            ]
        ]
        Field.div [] [
            Label.label [] [ str "Breitengrad" ]
            Control.div [] [
                Input.number [
                    Input.Placeholder "47.155999194351715"
                    model.Sensor.Latitude |> Inputs.optionalDoubleToString |> Input.Value
                    Input.OnChange(fun event ->
                        event.Value
                        |> Inputs.toDoubleOption
                        |> MapSensorListMsg.LatitudeUpdated
                        |> MapSensorList
                        |> dispatch
                    )
                ]
            ]
        ]
        Field.div [] [
            Label.label [] [ str "Längengrad" ]
            Control.div [] [
                Input.number [
                    Input.Placeholder "15.649259567260742"
                    model.Sensor.Longitude |> Inputs.optionalDoubleToString |> Input.Value
                    Input.OnChange(fun event ->
                        event.Value
                        |> Inputs.toDoubleOption
                        |> MapSensorListMsg.LongitudeUpdated
                        |> MapSensorList
                        |> dispatch
                    )
                ]
            ]
        ]
        Field.div [] [
            Label.label [] [ str "Höhe über Meeresspiegel [m]" ]
            Control.div [] [
                Input.number [
                    Input.Placeholder "400"
                    model.Sensor.Altitude |> Inputs.optionalDoubleToString |> Input.Value
                    Input.OnChange(fun event ->
                        event.Value
                        |> Inputs.toDoubleOption
                        |> MapSensorListMsg.AltitudeUpdated
                        |> MapSensorList
                        |> dispatch
                    )
                ]
            ]
        ]
        Field.div [] [
            Control.div [] [
                Button.button [
                    Button.IsLoading model.RetrieveCurrentLocationRunning
                    Button.Color Color.IsLink
                    match navigator.geolocation with
                    | Some geolocation ->
                        Button.OnClick(fun event ->
                            event.preventDefault ()
                            dispatch (MapSensorList MapSensorListMsg.CurrentLocationRequested)
                            deviceLocationOnClick dispatch geolocation
                        )
                    | None -> Button.Disabled true
                ] [ str "Aktuellen Standort übernehmen" ]
            ]
        ]
        Field.div [] [
            Checkbox.checkbox [] [
                Checkbox.input [
                    Props [
                        Checked model.Sensor.IsPublic
                        OnChange(fun _ ->
                            not model.Sensor.IsPublic
                            |> MapSensorListMsg.IsPublicUpdated
                            |> MapSensorList
                            |> dispatch
                        )
                    ]
                ]
                str " Sensor öffentlich sichtbar"
            ]
        ]

        Field.div [] [
            Label.label [] [ str "Status" ]
            Control.div [ Control.IsExpanded ] [ stateSelect dispatch model.Sensor.State ]
        ]

        Field.div [] [
            Label.label [] [ str "Sensor Typ" ]
            Control.div [ Control.IsExpanded ] [
                sensorTypeSelect (Option.isSome model.Sensor.Id) model.Sensor.SensorType dispatch
            ]
        ]

        Field.div [] [
            Label.label [] [ str "Physikalischer Sensor" ]
            Control.div [ Control.IsExpanded ] [
                physicalSensorSelect model.Sensor.TtnSensorSelected model.TtnSensors dispatch
            ]
        ]

        Field.div [] [
            Label.label [] [ str "Benutzer" ]
            Control.div [ Control.IsExpanded ] [
                UserSelect.view
                    (MapSensorListMsg.SelectedUserUpdated >> MapSensorList >> dispatch)
                    model.Sensor.UserSelected
                    model.Users
            ]
        ]
        Field.div [] [
            Label.label [] [ str "Interne Notiz" ]
            Control.div [] [
                Input.text [
                    model.Sensor.Note |> Option.defaultValue "" |> Input.Value
                    Input.OnChange(fun event ->
                        event.Value
                        |> String.toOption
                        |> MapSensorListMsg.NoteUpdated
                        |> MapSensorList
                        |> dispatch
                    )
                ]
            ]
        ]
        Field.div [] [
            Label.label [] [ str "Datum letzte Benutzer-Zuweisung" ]
            Control.div [] [
                Input.text [
                    Input.Disabled true
                    model.Sensor.LastAssignmentDate
                    |> Option.map DateTime.toDateString
                    |> Option.defaultValue "-"
                    |> Input.Value
                ]
            ]
        ]
    ]

let private createUpdateMapSensor
    (id: int)
    (name: string)
    (location: Location)
    (altitude: float option)
    (isPublic: bool)
    (state: MapSensorStateDto)
    (sensorType: SensorType)
    (selectedTtnSensor: ConfiguredPhysicalSensor option)
    (selectedUser: UserDto option)
    (note: string option)
    (lastAssignmentDate: DateTime option)
    (stateSince: DateTimeOffset)
    =

    let baseData = {
        Name = name
        IsPublic = isPublic
        State = state
        Location = location
        Altitude = altitude
        TtnSensorId = Option.map (fun selected -> selected.BaseData.DeviceEui) selectedTtnSensor
        UserId = Option.map (fun (selected: UserDto) -> selected.Id) selectedUser
        Note = note
        LastAssignmentDate = lastAssignmentDate
        StateSince = stateSince
    }

    let sensorData =
        match sensorType with
        | SensorType.Air -> MapSensorDto.Air baseData
        | SensorType.Soil ->
            MapSensorDto.Soil {
                Base = baseData
                WetLimit = None
                DryLimit = None
            }
        | SensorType.RainFall -> MapSensorDto.RainFall baseData
        | SensorType.LeafletMoisture -> MapSensorDto.LeafletMoisture baseData
        | SensorType.PH -> MapSensorDto.PH baseData
        | SensorType.WindAverage -> MapSensorDto.WindAverage baseData
        | SensorType.WeatherStation -> MapSensorDto.WeatherStation baseData

    IdValue.create id sensorData


let createUpdateSensorMsg
    id
    maybeName
    maybeLatitude
    maybeLongitude
    altitude
    isPublic
    isDisabled
    sensorType
    selectedTtnSensor
    selectedUser
    note
    (lastAssignmentDate: DateTime option)
    (stateSince: DateTimeOffset)
    =
    let maybeLocation =
        Option.map2
            (fun latitude longitude -> {
                Latitude = latitude
                Longitude = longitude
            })
            maybeLatitude
            maybeLongitude

    Option.map2
        (fun name location ->
            createUpdateMapSensor
                id
                name
                location
                altitude
                isPublic
                isDisabled
                sensorType
                selectedTtnSensor
                selectedUser
                note
                lastAssignmentDate
                stateSince
        )
        maybeName
        maybeLocation
    |> Option.map MapSensorListMsg.UpdateSensor

let private createCreateMapSensor
    (name: string)
    (location: Location)
    (altitude: float option)
    (isPublic: bool)
    (state: MapSensorStateDto)
    (sensorType: SensorType)
    (selectedTtnSensor: ConfiguredPhysicalSensor option)
    (selectedUser: UserDto option)
    (note: string option)
    (lastAssignmentDate: DateTime option)
    =

    let baseData = {
        Name = name
        IsPublic = isPublic
        State = state
        Location = location
        Altitude = altitude
        TtnSensorId = Option.map (fun selected -> selected.BaseData.DeviceEui) selectedTtnSensor
        UserId = Option.map (fun (selected: UserDto) -> selected.Id) selectedUser
        Note = note
        LastAssignmentDate = lastAssignmentDate
        StateSince = DateTimeOffset.UtcNow
    }

    match sensorType with
    | SensorType.Air -> MapSensorDto.Air baseData
    | SensorType.Soil ->
        MapSensorDto.Soil {
            Base = baseData
            WetLimit = None
            DryLimit = None
        }
    | SensorType.RainFall -> MapSensorDto.RainFall baseData
    | SensorType.LeafletMoisture -> MapSensorDto.LeafletMoisture baseData
    | SensorType.PH -> MapSensorDto.PH baseData
    | SensorType.WindAverage -> MapSensorDto.WindAverage baseData
    | SensorType.WeatherStation -> MapSensorDto.WeatherStation baseData


let createCreateSensorMsg
    maybeName
    maybeLatitude
    maybeLongitude
    altitude
    isPublic
    isDisabled
    sensorType
    selectedTtnSensor
    selectedUser
    note
    lastAssignmentDate
    =
    Option.map3
        (fun name latitude longitude ->
            let location = {
                Latitude = latitude
                Longitude = longitude
            }

            createCreateMapSensor
                name
                location
                altitude
                isPublic
                isDisabled
                sensorType
                selectedTtnSensor
                selectedUser
                note
                lastAssignmentDate
        )
        maybeName
        maybeLatitude
        maybeLongitude
    |> Option.map MapSensorListMsg.CreateSensor


let saveButton dispatch (model: Model) =
    let maybeOnClick =
        match model.Sensor.Id with
        | Some id ->
            createUpdateSensorMsg
                id
                model.Sensor.Name
                model.Sensor.Latitude
                model.Sensor.Longitude
                model.Sensor.Altitude
                model.Sensor.IsPublic
                model.Sensor.State
                model.Sensor.SensorType
                model.Sensor.TtnSensorSelected
                model.Sensor.UserSelected
                model.Sensor.Note
                model.Sensor.LastAssignmentDate
                model.Sensor.StateSince
        | None ->
            createCreateSensorMsg
                model.Sensor.Name
                model.Sensor.Latitude
                model.Sensor.Longitude
                model.Sensor.Altitude
                model.Sensor.IsPublic
                model.Sensor.State
                model.Sensor.SensorType
                model.Sensor.TtnSensorSelected
                model.Sensor.UserSelected
                model.Sensor.Note
                model.Sensor.LastAssignmentDate
        |> Option.map (fun msg -> Button.OnClick(fun _ -> dispatch (MapSensorList msg)))

    let buttonOptions = [
        Button.IsLoading model.RequestRunning
        Button.Color IsSuccess
        Button.Disabled(Option.isNone maybeOnClick)
    ]

    Button.button (List.addToListIfSome buttonOptions maybeOnClick) [ str "Speichern" ]


let view dispatch closeModal (model: Model) =
    let headline =
        if Option.isSome model.Sensor.Id then
            sprintf "MySens Sensor '%s' bearbeiten" (Option.defaultValue "" model.Sensor.Name)
        else
            sprintf "Neuen Sensor erstellen"

    Modal.modal [ Modal.IsActive true ] [
        Modal.background [ Props [ OnClick closeModal ] ] []
        Modal.Card.card [] [
            Modal.Card.head [] [
                Modal.Card.title [] [ str headline ]
                Delete.delete [ Delete.OnClick closeModal ] []
            ]
            Modal.Card.body [] [ Content.content [] [ form dispatch model ] ]
            Modal.Card.foot [] [ saveButton dispatch model ]
        ]
    ]

let update (msg: MapSensorListMsg) (model: Model) =
    match msg with
    | MapSensorListMsg.SelectedSensorUpdated selectedSensor ->
        let newSensor = { model.Sensor with TtnSensorSelected = selectedSensor }

        { model with Sensor = newSensor }, Cmd.none
    | MapSensorListMsg.SelectedUserUpdated selectedUser ->
        let newSensor = {
            model.Sensor with
                UserSelected = selectedUser
                LastAssignmentDate = Some DateTime.Now
        }

        { model with Sensor = newSensor }, Cmd.none
    | MapSensorListMsg.SensorNameUpdated name ->
        let newSensor = { model.Sensor with Name = name }

        { model with Sensor = newSensor }, Cmd.none
    | MapSensorListMsg.IsPublicUpdated isPublic ->
        let newSensor = { model.Sensor with IsPublic = isPublic }

        { model with Sensor = newSensor }, Cmd.none
    | MapSensorListMsg.NoteUpdated note ->
        let newSensor = { model.Sensor with Note = note }

        { model with Sensor = newSensor }, Cmd.none
    | MapSensorListMsg.SelectedStateUpdated state ->
        let newSensor = {
            model.Sensor with
                State = state
                StateSince = DateTimeOffset.UtcNow
        }

        { model with Sensor = newSensor }, Cmd.none
    | MapSensorListMsg.LatitudeUpdated latitude ->
        let newSensor = { model.Sensor with Latitude = latitude }

        { model with Sensor = newSensor }, Cmd.none
    | MapSensorListMsg.LongitudeUpdated longitude ->
        let newSensor = { model.Sensor with Longitude = longitude }

        { model with Sensor = newSensor }, Cmd.none
    | MapSensorListMsg.AltitudeUpdated altitude ->
        let newSensor = { model.Sensor with Altitude = altitude }

        { model with Sensor = newSensor }, Cmd.none
    | MapSensorListMsg.SelectedSensorTypeUpdated sensorType ->
        let newSensor = { model.Sensor with SensorType = sensorType }

        { model with Sensor = newSensor }, Cmd.none
    | MapSensorListMsg.CurrentLocationRequested -> { model with RetrieveCurrentLocationRunning = true }, Cmd.none
    | MapSensorListMsg.CurrentLocationFailed error ->
        let toast =
            Toast.create "Beim Auslesen der GPS Koordinaten ist ein Fehler aufgetreten"
            |> Toast.error

        { model with RetrieveCurrentLocationRunning = false }, toast
    | MapSensorListMsg.CurrentLocationReceived position ->
        let newSensor = {
            model.Sensor with
                Latitude = Some position.coords.latitude
                Longitude = Some position.coords.longitude
                Altitude = position.coords.altitude |> Option.map (Math.round 0)
        }

        let toastCmd =
            Toast.create "GPS Koordinaten erfolgreich übernommen" |> Toast.success

        {
            model with
                Sensor = newSensor
                RetrieveCurrentLocationRunning = false
        },
        toastCmd
    | _ -> model, Cmd.none