module Client.Leaflet

open System
open Client.DomainTypes
open Client.Infrastructure
open Client.Map
open Fable.Core
open Fable.React
open Fable.React.Props
open Fulma
open Fulma.Extensions.Wikiki
open Msg
open ReactLeaflet
open Shared
open Shared.DtoTypes.MapSensorData
open Shared.DtoTypes.LeafletWetness
open Shared.Infrastructure
open Leaflet
open Shared.Dto.MapSensorData
open Fable.Core.JsInterop

let popupSecondColCls = "second-column"
let popupMeasurementCls = "measurement-data"

let iconCls = "icon"

let signalStrengthCls = "signal-strength"

let rainIntensityCls = "rain-intensity"

let wideIconCls = "wide-icon"

let leafletWetnessCls = "leaflet-wetness"

let detailsButtonCls = "details-button"

type IconFactory =
    abstract createIcon: props: obj -> Leaflet.Icon<obj>

[<ImportAll("./icon.js")>]
let iconFactory: IconFactory = jsNative

type IconProps =
    | IconUrl of string
    | IconSize of obj array
    | IconAnchor of obj array
    | PopupAnchor of obj array
    | TooltipAnchor of obj array

let makePosition latitude longitude =
    LatLngExpression.Case3(latitude, longitude)

let createIcon (fileName: string) =
    let iconOptions =
        keyValueList CaseRules.LowerFirst [
            IconUrl(sprintf "flags/%s" fileName)
            IconSize [| box 25; box 41 |]
            IconAnchor [| box 12; box 41 |]
            PopupAnchor [| box 0; box -42 |]
        ]

    iconFactory.createIcon iconOptions |> U2.Case1

let getTotalMinutesLimit (data: MapSensorData) =
    match data with
    | NoDataAvailable
    | NoPublicData -> None
    | WeatherStationData _ -> Some 15.0
    | AirData _
    | RainFallData _
    | AverageWindSpeedData _ -> Some 30.0
    | GroundData _
    | LeafletMoistureData _
    | SoilPhData _ -> Some 50.0

let isOldAgeData (data: MapSensorData) =
    let totalMinutesLimit = (getTotalMinutesLimit data)

    getSensorDataDate data
    |> Option.map (fun date -> (DateTimeOffset.UtcNow - date).TotalMinutes)
    |> Option.map2 (fun limit totalMinutes -> totalMinutes > limit) totalMinutesLimit
    |> Option.defaultValue true


let getIconNameByMapSensorData (data: MapSensorData) =
    let isOldAge = isOldAgeData data

    match (data, isOldAge) with
    | NoDataAvailable, _
    | NoPublicData, _ -> "Grey.png"
    | AirData _, true -> "TL_grey.svg"
    | AirData _, false -> "TL.svg"
    | GroundData _, true -> "BT_grey.svg"
    | GroundData _, false -> "BT_brown.svg"
    | RainFallData _, true -> "RF_grey.svg"
    | RainFallData _, false -> "RF_blue.svg"
    | LeafletMoistureData _, true -> "BN_grey.svg"
    | LeafletMoistureData _, false -> "BN_blue.svg"
    | SoilPhData _, true -> "PH_grey.svg"
    | SoilPhData _, false -> "PH_gold.svg"
    | AverageWindSpeedData _, true -> "WI_grey.svg"
    | AverageWindSpeedData _, false -> "WI_violett.svg"
    | WeatherStationData _, true -> "WS_grey.svg"
    | WeatherStationData _, false -> "WS_orange.svg"

let getSensorNameLinkColor (data: MapSensorData) =
    match data with
    | NoDataAvailable
    | NoPublicData -> "black"
    | AirData _ -> "#369fd9"
    | GroundData _ -> "#8f5c30"
    | RainFallData _ -> "#0033ab"
    | LeafletMoistureData _ -> "#4c70c4"
    | SoilPhData _ -> "#cc9933"
    | AverageWindSpeedData _ -> "#663399"
    | WeatherStationData _ -> "#E6821E"


let makeMarker data latLong popupContent =
    let icon = getIconNameByMapSensorData data |> createIcon

    marker [
        MarkerProps.Position latLong
        MarkerProps.Icon icon
    ] [
        popup [
            PopupProps.KeepInView false
            PopupProps.MaxWidth 800.
            PopupProps.MinWidth 250.
        ] [ popupContent ]
    ]

let temperatureToString = sprintf "%.2f °C"

let conductivityToString conductivity = sprintf "%.0f μS/cm" conductivity

let percentageToString = sprintf "%.2f %%"

let rainFallToString = sprintf " %.1f mm"

let windSpeedToString = sprintf "%.1f km/h"

let windDirectionToString = sprintf "%.1f °"

let noSensorDataToolTip = [
    tr [] [
        td [ ColSpan 2 ] [ str "Heute noch keine Daten empfangen" ]
    ]
]

let popupUpdateRow date =
    let timeElapsed = (DateTime.Now - date) |> TimeSpan.toHumanReadable

    let timeElapsedToolTip =
        sprintf "%s (%s)" (DateTime.toDayMonthString date) (DateTime.toTimeString date)

    tr [] [
        td [] [ str "Aktualisiert:" ]
        td [
            classList [
                (popupSecondColCls, true)
                (Tooltip.ClassName, true)
            ]
            Tooltip.dataTooltip timeElapsedToolTip
        ] [ sprintf "vor %s" timeElapsed |> str ]
    ]

let popupDataRow label number =
    tr [] [
        td [] [ sprintf "%s:" label |> str ]
        td [
            classList [
                popupSecondColCls, true
                (popupMeasurementCls, true)
            ]
        ] [ str number ]
    ]

let createDetailsSensorNameLink props (sensor: MapSensor) dispatch =
    let customProps = [
        sensor.Id
        |> Route.MySensSensor
        |> Clickable.onClickGoToRoute dispatch
        |> OnClick
        :> IHTMLProp
        Style [ Color(getSensorNameLinkColor sensor.Data) ] :> IHTMLProp
    ]

    a (List.append customProps props) [ str sensor.Name ]

let makeMarkerPopupHeader (sensor: MapSensor) dispatch =
    let factory =
        if sensor.DetailsAllowed then
            createDetailsSensorNameLink
        else
            fun props sensor _ -> span props [ str sensor.Name ]

    let nameProperties: IHTMLProp list = [ Class "popup-sensor-name" :> IHTMLProp ]

    let sensorName = factory nameProperties sensor dispatch


    tr [ Class "header" ] [
        td [] [ sensorName ]
        td [ Class popupSecondColCls ] [ str (DateTime.toDateString DateTime.Now) ]
    ]

let private createPopupSymbol cls toolTip src =
    div [
        classList [
            Tooltip.ClassName, true
            iconCls, true
            cls, true
        ]
        Tooltip.dataTooltip toolTip
    ] [ img [ Src src ] ]

let makeMarkerSymbolRow (data: MapSensorData) =
    let signalStrength = SignalStrength.fromMapSensorData data

    let leafletWetness = LeafletWetness.getLeafletWetness data

    let leafletMoisture = LeafletWetness.getLeafletMoisture data

    let rainIntensity = RainFall.getRainFallIntensity data

    let averageWindSpeed = AverageWindSpeed.mapSensorDataToAverageWindSpeed data

    let soilPh = SoilPh.toSoilPhData data

    let soilMoisture = SoilMoisture.getGroundMoisture data

    let leftContent =
        List.choose id [
            signalStrength
            |> Option.map SignalStrength.toImageSrc
            |> Option.map (createPopupSymbol signalStrengthCls "Empfangsstärke")
            (getBatteryLevel data)
            |> Option.map BatteryLevel.toImageSrc
            |> Option.map (createPopupSymbol signalStrengthCls "Batterie")
        ]

    let leafletWetnessTooltipText =
        match leafletWetness with
        | Some(Wet _) -> "Blatt nass"
        | Some Dry -> "Blatt trocken"
        | None -> ""

    let rightContent =
        List.choose id [
            leafletWetness
            |> Option.map LeafletWetness.toImageSrc
            |> Option.map (createPopupSymbol leafletWetnessCls leafletWetnessTooltipText)
            leafletMoisture
            |> Option.map LeafletWetness.percentageToImageSrc
            |> Option.map (createPopupSymbol rainIntensityCls "Blattnässe")
            rainIntensity
            |> Option.map RainFall.toImageSrc
            |> Option.map (createPopupSymbol rainIntensityCls "Regenmenge")
            averageWindSpeed
            |> Option.map AverageWindSpeed.getIconForWindSpeed
            |> Option.map (createPopupSymbol wideIconCls "Windgeschwindigkeit")
            soilPh
            |> Option.map SoilPh.toImageSrc
            |> Option.map (createPopupSymbol wideIconCls "PH-Wert")
            soilMoisture
            |> Option.map SoilMoisture.toImageSrc
            |> Option.map (createPopupSymbol leafletWetnessCls "Bodenfeuchtigkeit")
        ]

    tr [] [
        td [] leftContent
        td [ Class popupSecondColCls ] rightContent
    ]

type private AggregatedValue = {
    Label: string
    SubLabel: string
    Value: string
}

let private createAggregatedValueRow (index: int) (value: AggregatedValue) =
    let todayColumnContent = if index = 0 then [ str "Heute" ] else []

    let labelColumn = [
        str value.Label
        sub [] [ str value.SubLabel ]
        str ":"
    ]

    tr [] [
        td [] todayColumnContent
        td [] labelColumn
        td [ Class popupSecondColCls ] [ str value.Value ]
    ]

let private aggregatedValuesDailyPopup aggregatedValues =
    let rows = List.mapi createAggregatedValueRow aggregatedValues

    let table = table [ Style [ Width "100%" ] ] [ tbody [] rows ]

    tr [] [ td [ ColSpan 2 ] [ table ] ]

let private createAggregatedMinMaxTemperaturePopup minValue maxValue =
    let datedTemperatureString = DatedValue.toString temperatureToString

    [
        {
            Label = "T"
            SubLabel = "min"
            Value = datedTemperatureString minValue
        }
        {
            Label = "T"
            SubLabel = "max"
            Value = datedTemperatureString maxValue
        }
    ]

let private createAggregatedMinMaxHumidityPopup minValue maxValue =
    let datedPercentageString = DatedValue.toString percentageToString

    [
        {
            Label = "BF"
            SubLabel = "min"
            Value = datedPercentageString minValue
        }
        {
            Label = "BF"
            SubLabel = "max"
            Value = datedPercentageString maxValue
        }
    ]

let private createAggregatedMaxWindSpeedPopup (maxValue: DatedValue<float> option) =
    let datedWindSpeedString = DatedValue.toString windSpeedToString

    let value =
        match maxValue with
        | Some max -> datedWindSpeedString max
        | None -> "-"

    [
        {
            Label = "Wind"
            SubLabel = "max"
            Value = value
        }
    ]

let noPublicSensorDataPopup = [
    tr [] [
        td [ ColSpan 2 ] [
            str
                "Für diesen Sensor gibt es keine öffentlich sichtbaren Daten. Wenn du die Daten sehen möchtest, dann logge dich bitte ein"
        ]
    ]
]

let airDataToPopup (data: AirSensorData) = [
    popupUpdateRow data.Date.LocalDateTime
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "Temperatur" (temperatureToString data.AirTemperature)
    popupDataRow "Rel. Luftfeuchte" (sprintf " %.2f %%" data.AirHumidity)
    popupDataRow "Feuchttemperatur" (temperatureToString data.HumidTemperature)
    popupDataRow "Taupunkt" (temperatureToString data.DewPoint)
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    createAggregatedMinMaxTemperaturePopup data.MinAirTemperature data.MaxAirTemperature
    |> aggregatedValuesDailyPopup
]

let groundDataToPopup (data: GroundSensorData) = [
    popupUpdateRow data.Date.LocalDateTime
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "Rel. Bodenfeuchte" (sprintf " %.2f %%" data.GroundHumidity)
    popupDataRow "Bodentemperatur" (temperatureToString data.GroundTemperature)
    popupDataRow "Leitfähigkeit" (conductivityToString data.SoilConductivity)
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    createAggregatedMinMaxHumidityPopup data.MinGroundHumidity data.MaxGroundHumidity
    |> aggregatedValuesDailyPopup
]

let rainFallDataToPopup (data: RainFallSensorData) =
    let maybeRainFallToString = Option.map rainFallToString >> Option.defaultValue "-"

    [
        popupUpdateRow data.Date.LocalDateTime
        tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
        popupDataRow "Regen aktuell" (rainFallToString data.CurrentRainFall)
        popupDataRow "Regen letzte Stunde" (maybeRainFallToString data.RainFallLastHour)
        popupDataRow "Regen letzte 24 Stunden" (maybeRainFallToString data.RainFallLast24h)
    ]

let leafletMoistureDataToPopup (data: LeafletMoistureSensorData) = [
    popupUpdateRow data.Date.LocalDateTime
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "Blattnässe" (percentageToString data.LeafletMoisture)
    popupDataRow "Blatttemperatur" (temperatureToString data.LeafletTemperature)
]

let soilPhDataToPopup (data: SoilPhSensorData) = [
    popupUpdateRow data.Date.LocalDateTime
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "PH-Wert" (sprintf "%.2f" data.SoilPh)
    popupDataRow "Bodentemperatur" (temperatureToString data.SoilTemperature)
]

let averageWindSpeedDataToPopup (data: AverageWindSensorData) = [
    popupUpdateRow data.Date.LocalDateTime
    tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
    popupDataRow "Ø Windgeschwindigkeit" (windSpeedToString data.AverageWindSpeed)
]

let private createCurrentWindRow (currentWind: Wind) : ReactElement =
    let direction =
        match currentWind with
        | Wind wind ->
            sprintf
                "%s (%s)"
                (windSpeedToString wind.WindSpeed)
                (windDirectionToCompassDirection wind.WindDirection |> compassDirectionToLabel)
        | NoWind -> "-"

    popupDataRow "akt. Wind" direction

let private createAverageWindSpeedRow (maybeAverageWindSpeed: float option) =
    let text =
        match maybeAverageWindSpeed with
        | Some average -> windSpeedToString average
        | None -> windSpeedToString 0.0

    popupDataRow "durchschn. Windgeschw." text


let weatherStationDataToPopup (data: WeatherStationSensorData) =
    let aggregatedValues =
        [
            createAggregatedMinMaxTemperaturePopup data.MinTemperature data.MaxTemperature
            createAggregatedMaxWindSpeedPopup data.MaxWindSpeed
        ]
        |> List.collect id
        |> aggregatedValuesDailyPopup

    [
        popupUpdateRow data.Date.LocalDateTime
        tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
        popupDataRow "Temperatur" (temperatureToString data.AirTemperature)
        popupDataRow "Rel. Luftfeuchte" (sprintf " %.2f %%" data.AirHumidity)
        createAverageWindSpeedRow data.AverageWindSpeed
        createCurrentWindRow data.CurrentWind
        tr [] [ td [ ColSpan 2 ] [ hr [] ] ]
        aggregatedValues
    ]

let sensorDataToPopup (data: MapSensorData) =
    match data with
    | NoDataAvailable -> noSensorDataToolTip
    | NoPublicData -> noPublicSensorDataPopup
    | AirData airData -> airDataToPopup airData
    | GroundData groundData -> groundDataToPopup groundData
    | RainFallData rainFallData -> rainFallDataToPopup rainFallData
    | LeafletMoistureData sensorData -> leafletMoistureDataToPopup sensorData
    | SoilPhData sensorData -> soilPhDataToPopup sensorData
    | AverageWindSpeedData sensorData -> averageWindSpeedDataToPopup sensorData
    | WeatherStationData weatherStationSensorData -> weatherStationDataToPopup weatherStationSensorData

let detailsButtonRow (sensor: MapSensor) dispatch =
    tr [] [
        td [ ColSpan 2; Class detailsButtonCls ] [
            Button.button [
                Button.Color Color.IsLink
                Button.OnClick(fun _ -> dispatch (Route.MySensSensor sensor.Id |> GoToRoute |> Global))
                Button.IsOutlined
            ] [ str "Detailseite" ]
        ]
    ]


let makeMarkerPopup (sensor: MapSensor) dispatch =
    let rows = [
        makeMarkerPopupHeader sensor dispatch
        makeMarkerSymbolRow sensor.Data
    ]

    let rows = List.append rows (sensorDataToPopup sensor.Data)

    table [ Style [ Width "100%" ] ] [ tbody [] rows ]