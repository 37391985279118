module Client.Forms.PhysicalSensor

open Client.Msg
open Fulma
open Microsoft.FSharp.Core
open Shared.DtoTypes.PhysicalSensor
open Shared.Infrastructure
open Shared.Dto.Dto
open Fable.React
open Fable.React.Props

type Model = {
    EditExisting: bool
    Name: string option
    AppEui: string option
    DeviceEui: string option
    AppKey: string option
    SelectedType: SensorModel
    Description: string option
    RequestRunning: bool
}


type Msg =
    | NameUpdated
    | SelectedTypeUpdated

let init (maybeExistingSensor: PhysicalSensor option) =
    match maybeExistingSensor with
    | Some(Configured existing) -> {
        EditExisting = true
        Name = Some existing.BaseData.Name
        AppEui = Some existing.BaseData.AppEui
        DeviceEui = Some existing.BaseData.DeviceEui
        AppKey = Some existing.BaseData.AppKey
        SelectedType = existing.Configuration
        Description = existing.BaseData.Description
        RequestRunning = false
      }
    | Some(UnConfigured existing) -> {
        EditExisting = true
        Name = Some existing.Name
        AppEui = Some existing.AppEui
        DeviceEui = Some existing.DeviceEui
        AppKey = Some existing.AppKey
        SelectedType = LHT65
        Description = existing.Description
        RequestRunning = false
      }
    | None -> {
        EditExisting = false
        Name = None
        AppEui = None
        DeviceEui = None
        AppKey = None
        SelectedType = LHT65
        Description = None
        RequestRunning = false
      }

let private tryCreateSensor (model: Model) =
    let maybeBaseData =
        Some createPhysicalSensorBaseData
        |> Option.apply (model.DeviceEui |> Option.map (fun eui -> sprintf "eui-%s" (eui.ToLower())))
        |> Option.apply model.Name
        |> Option.apply model.DeviceEui
        |> Option.apply model.AppEui
        |> Option.apply model.AppKey
        |> Option.apply (Some model.Description)

    Option.map
        (fun baseData -> {
            BaseData = baseData
            Configuration = model.SelectedType
        })
        maybeBaseData


let modelToOption sensorModel isSelected =
    let sensorModelString = sensorModelToString sensorModel

    option [ Value sensorModelString; Selected isSelected ] [ str sensorModelString ]

let sensorModelSelect (selected: SensorModel) dispatch =
    let modelList = [
        LHT65
        LSN50v2_S31
        S31_LS
        S31_LB
        LSE01
        SE01_LS
        SE01_LB
        LSN50v2_Rain_01mm
        LSN50v2_Rain_02mm
        SN50v3_LS_Rain_01mm
        SN50v3_LB_Rain_01mm
        SN50v3_LS_Rain_02mm
        SN50v3_LB_Rain_02mm
        LLMS01
        LSPH01
        LSN50v2_Wind
        SN50v3_LS_Wind
        SN50v3_LB_Wind
        WSC1L
    ]

    let options =
        modelList
        |> List.map (fun sensorModel -> modelToOption sensorModel (selected = sensorModel))

    Select.select [ Select.IsFullWidth ] [
        select
            [
                DefaultValue(
                    List.tryFind (fun sensorType -> sensorType = selected) modelList
                    |> Option.defaultValue LHT65
                    |> sensorModelToString
                )
                OnChange(fun event ->
                    (sensorTypeFromString event.Value |> Option.get)
                    |> PhysicalSensorFormMsg.SelectedSensorModelChanged
                    |> dispatch
                )
            ]
            options
    ]

let private createFormField label id control =
    Field.div [] [
        Label.label [ Label.For id ] [ str label ]
        control
    ]

let private createSensorTypeField sensorType dispatch =
    let control =
        Control.div [ Control.IsExpanded ] [ sensorModelSelect sensorType dispatch ]

    createFormField "Sensor-Modell" "sensor_model" control

let private createNameField name dispatch =
    let id = "name"

    let control =
        Control.div [ Control.IsExpanded ] [
            Input.text [
                Input.Placeholder "MS-TLxxx"
                Input.Value(Option.defaultValue "" name)
                Input.OnChange(fun event ->
                    event.Value |> String.toOption |> PhysicalSensorFormMsg.NameChanged |> dispatch
                )
            ]
        ]

    createFormField "Eindeutiger Name des Sensors" id control

let private createAppEuiField appEui dispatch =
    let id = "app_eui"

    let control =
        Control.div [ Control.IsExpanded ] [
            Input.text [
                Input.Value(Option.defaultValue "" appEui)
                Input.OnChange(fun event ->
                    event.Value
                    |> String.toOption
                    |> PhysicalSensorFormMsg.AppEuiChanged
                    |> dispatch
                )
            ]
        ]

    createFormField "App EUI" id control

let private createDeviceEuiField editExisting deviceEui dispatch =
    let id = "device_eui"

    let control =
        Control.div [ Control.IsExpanded ] [
            Input.text [
                Input.Disabled editExisting
                Input.Value(Option.defaultValue "" deviceEui)
                Input.OnChange(fun event ->
                    event.Value
                    |> String.toOption
                    |> PhysicalSensorFormMsg.DeviceEuiChanged
                    |> dispatch
                )
            ]
        ]

    createFormField "Device EUI" id control

let private createAppKeyField appKey dispatch =
    let id = "app_key"

    let control =
        Control.div [ Control.IsExpanded ] [
            Input.text [
                Input.Value(Option.defaultValue "" appKey)
                Input.OnChange(fun event ->
                    event.Value
                    |> String.toOption
                    |> PhysicalSensorFormMsg.AppKeyChanged
                    |> dispatch
                )
            ]
        ]

    createFormField "App Key" id control

let private createDescriptionField description dispatch =
    let id = "description"

    let control =
        Control.div [ Control.IsExpanded ] [
            Input.text [
                Input.Value(Option.defaultValue "" description)
                Input.OnChange(fun event ->
                    event.Value
                    |> String.toOption
                    |> PhysicalSensorFormMsg.DescriptionChanged
                    |> dispatch
                )
            ]
        ]

    createFormField "Beschreibung" id control

let form dispatch (model: Model) =
    form [] [
        Field.div [] [
            createNameField model.Name dispatch
            createAppEuiField model.AppEui dispatch
            createDeviceEuiField model.EditExisting model.DeviceEui dispatch
            createAppKeyField model.AppKey dispatch
            createSensorTypeField model.SelectedType dispatch
            createDescriptionField model.Description dispatch
        ]
    ]

let buttonOptions (model: Model) dispatch =
    let maybeSensor = tryCreateSensor model

    let dynamicButtonOptions =
        match maybeSensor with
        | Some sensor ->
            let onClick =
                if model.EditExisting then
                    PhysicalSensorListMsg.UpdateSensor
                else
                    PhysicalSensorListMsg.CreateSensor

            [
                Button.Disabled false
                Button.OnClick(fun _ -> dispatch (onClick sensor))
            ]
        | None -> [ Button.Disabled true ]

    List.append
        [
            Button.IsLoading model.RequestRunning
            Button.Color IsSuccess
        ]
        dynamicButtonOptions

let view dispatch (model: Model) =
    let closeModal = (fun _ -> dispatch PhysicalSensorListMsg.CloseModal)

    let headline =
        if model.EditExisting then
            "Bestehenden Sensor bearbeiten"
        else
            "Neuer physikalischer Sensor erstellen"

    Modal.modal [ Modal.IsActive true ] [
        Modal.background [ Props [ OnClick closeModal ] ] []
        Modal.Card.card [] [
            Modal.Card.head [] [
                Modal.Card.title [] [ str headline ]
                Delete.delete [ Delete.OnClick closeModal ] []
            ]
            Modal.Card.body [] [
                Content.content [] [
                    form (PhysicalSensorListMsg.Form >> dispatch) model
                ]
            ]
            Modal.Card.foot [] [
                Button.button (buttonOptions model dispatch) [ str "Speichern" ]
            ]
        ]
    ]