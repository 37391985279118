module Client.Page.MySettings

open Client.Api
open Client.Components
open Client.Domain
open Client.DomainTypes
open Client.InfrastructureTypes
open Elmish
open Fable.React.Props
open Fulma
open Shared
open Shared.Dto.Page.UserList
open Shared.Dto.User
open Fable.React
open Client.Forms
open Client.Msg
open Shared.Dto.Dto
open Shared.Infrastructure


type DataModel = {
    Session: UserSession
    Profile: FullUserDto
    ChangePasswordModel: ChangePassword.Model
    OpenTab: MySettingsTabs.Tab
}

type Model = Loadable<DataModel, UserSession>

let private profileBox (model: DataModel) =
    let heading = Heading.p [] [ str "Benutzerdaten" ]

    let form =
        form [] [
            Field.div [ Field.IsHorizontal ] [
                Field.body [] [
                    Field.div [] [
                        Label.label [] [ str "Vorname" ]

                        Input.text [
                            Input.IsReadOnly true
                            Input.IsStatic true
                            Input.Value(getFirstName model.Profile)
                        ]
                    ]

                    Field.div [] [
                        Label.label [] [ str "Nachname" ]

                        Input.text [
                            Input.IsReadOnly true
                            Input.IsStatic true
                            Input.Value(getLastName model.Profile)
                        ]
                    ]
                ]
            ]

            Field.div [] [
                Label.label [] [ str "E-Mail Adresse" ]
                Input.text [
                    Input.IsReadOnly true
                    Input.IsStatic true
                    Input.Value(getMail model.Profile)
                ]
            ]

            Field.div [ Field.IsHorizontal ] [
                Field.body [] [
                    Field.div [] [
                        Label.label [] [ str "Benutzer-Typ" ]
                        Input.text [
                            Input.IsReadOnly true
                            Input.IsStatic true
                            Input.Value(userTypeToString model.Profile)
                        ]
                    ]

                    match model.Profile with
                    | FullUserDto.Admin _
                    | FullUserDto.RegularUser _ -> ()
                    | FullUserDto.TestUser testUser ->
                        Field.div [] [
                            Label.label [] [ str "Benutzer gültig bis" ]
                            Input.text [
                                Input.IsReadOnly true
                                Input.IsStatic true
                                Input.Value(testUser.ValidUntil |> DateTime.toShortString)
                            ]
                        ]
                ]
            ]
        ]

    Box.wrapWithHeading heading [ form ]

let private passwordBox (model: DataModel) dispatch =
    let headingContent = str "Passwort ändern"

    let boxContent =
        ChangePassword.view model.ChangePasswordModel (MySettingsMsg.ChangePasswordForm >> dispatch)

    Box.wrapWithHeading headingContent [ boxContent ]

let private getUserPackages (user: FullUserDto) =
    match user with
    | FullUserDto.Admin _
    | FullUserDto.TestUser _ -> None
    | FullUserDto.RegularUser user -> Some user.Packages

let private userHasBookedPackages (user: FullUserDto) =
    match getUserPackages user with
    | Some _ -> true
    | None -> false

let bookedPackagesBox (model: DataModel) =
    match model.Profile with
    | FullUserDto.Admin _ -> None
    | FullUserDto.TestUser _ -> None
    | FullUserDto.RegularUser user ->
        let packages =
            user.Packages
            |> List.map (fun package -> package.Value.Package |> getPackageLabel)
            |> List.map (fun text -> li [] [ str text ])

        let headingContent = str "Gebuchte Pakete"

        [ Content.content [] [ ul [] packages ] ]
        |> Box.wrapWithHeading headingContent
        |> Some

let private createTab dispatch (label: string) (tab: MySettingsTabs.Tab) (openTab: MySettingsTabs.Tab) =
    let onClick = (fun _ -> dispatch (MySettingsMsg.ChangeTab tab))

    Tabs.tab [ Tabs.Tab.IsActive(tab = openTab) ] [ a [ DOMAttr.OnClick onClick ] [ str label ] ]

let private createTabView dispatch (model: DataModel) =
    match model.OpenTab with
    | MySettingsTabs.Profile -> profileBox model
    | MySettingsTabs.BoughtPackages ->
        bookedPackagesBox model
        |> Option.defaultValue (div [] [ str "Das darf nicht passieren" ])
    | MySettingsTabs.ChangePassword -> passwordBox model dispatch
    | MySettingsTabs.NotificationSettings ->
        Box.wrapWithHeading (str "Benachrichtigungs-Einstellungen") [ NotificationSettings.view ]

let dataView dispatch (model: DataModel) =
    let tabs =
        Tabs.tabs [ Tabs.Size IsMedium ] [
            createTab dispatch "Profil" MySettingsTabs.Profile model.OpenTab

            if userHasBookedPackages model.Profile then
                createTab dispatch "Gebuchte Pakete" MySettingsTabs.BoughtPackages model.OpenTab
            else
                ()

            createTab dispatch "Passwort ändern" MySettingsTabs.ChangePassword model.OpenTab
        ]

    Container.container [ Container.isFullWidth ] [
        Heading.h1 [] [ str "Einstellungen" ]

        tabs

        createTabView dispatch model
    ]

let init (session: UserSession) =
    let requestData: AuthenticatedRequest<unit> = {
        SessionKey = session.SessionKey
        Data = ()
    }

    Loadable.Loading session, Cmd.OfAsync.perform api.getMyProfile requestData (ProfileLoaded >> MySettings)

let map (modelMap: 'model -> Model) (cmdMap: 'msg -> Msg) (subModel: 'model, subCmd: Cmd<'msg>) =
    modelMap subModel, Cmd.map cmdMap subCmd

let update (msg: MySettingsMsg) (model: Model) =
    match msg, model with
    | MySettingsMsg.ChangePasswordForm formMsg, Loadable.Data data ->
        ChangePassword.update formMsg data.ChangePasswordModel
        |> (fun (formModel, cmd) ->
            Loadable.Data { data with ChangePasswordModel = formModel }, Cmd.map (ChangePasswordForm >> MySettings) cmd
        )
    | MySettingsMsg.ProfileLoaded response, Loading loading ->
        match response with
        | Result.Ok profile ->
            Loadable.Data {
                Session = loading
                Profile = profile
                ChangePasswordModel = ChangePassword.init loading
                OpenTab = MySettingsTabs.Profile
            },
            Cmd.none
        | Result.Error _ -> Loadable.Error "Ein Fehler beim Laden des Profils ist aufgetreten", Cmd.none
    | MySettingsMsg.ChangeTab tab, Loadable.Data data -> Loadable.Data { data with OpenTab = tab }, Cmd.none
    | _, _ -> model, Cmd.none

let view dispatch (model: Model) = Loadable.view (dataView dispatch) model