module Client.Page.MapSensorDataPage

open Client.Components.Graph.SoilPhSensor
open Client.Components.Graph.WeatherStation
open Client.Components.GraphCommon
open Client.Domain
open Client.InfrastructureTypes
open Fable.Remoting.Client
open Client
open Client.Components
open Fable.React
open Feliz.Bulma
open Microsoft.FSharp.Collections
open Shared
open System
open Client.Api
open Elmish
open Client.Msg
open Client.Components.Graph.AirSensor
open Client.Components.Graph.GroundSensor
open Client.Components.Graph.RainFallSensor
open Client.Components.Graph.LeafletMoistureSensor
open Client.Components.Graph.AverageWindSpeedSensor
open Client.Domain.PageSkeleton
open Fulma
open Fulma.Elmish
open Shared.Dto
open Shared.Dto.Dto
open Shared.Dto.MapSensorData

open Feliz
open Shared.Dto.SensorGraphData
open Shared.DtoTypes.DateRangeDto
open Shared.Infrastructure
open Thoth.Elmish

module R = Standard
module P = Fable.React.Props


type SensorGraphDataList =
    | AirList of AirSensorGraphData
    | GroundList of GroundSensorGraphData
    | RainFallList of RainFallSensorGraphData
    | LeafletMoistureList of SimpleGraphData<LeafletMoistureGraphNode>
    | SoilPhList of SimpleGraphData<SoilPhGraphNode>
    | AverageWindSpeedList of AverageWindSpeedGraphsData
    | WeatherStationList of WeatherStationGraphData

type DatePickerModel = {
    State: DatePicker.Types.State
    CurrentDate: DateTime option
}

type HistoryGraphData =
    | NotLoaded
    | NoData
    | Data of SensorGraphDataList

type DataModel = {
    Session: UserSession
    Name: string
    SensorId: int
    CurrentData: MapSensorData
    GraphRequestRunning: bool
    DownloadRequestRunning: bool
    DownloadRange: DateRangeOption
    AvailableDownloadRanges: DateRangeOption list
    GraphDateRange: SensorDetailPage.GraphVisualisation<DateRangePicker.Data>
    HistoryGraphData: HistoryGraphData
}

type PageModel =
    | NotFound
    | NotActive
    | NotAllowed
    | NotAuthenticated
    | Data of DataModel

type Model = Loadable<PageModel, UserSession>

let init id (session: UserSession) : Model * Cmd<Msg> =
    let requestData = {
        SessionKey = session.SessionKey
        Data = id
    }

    let cmd =
        Cmd.OfAsync.perform api.getSensorDetailPageData requestData (MySensSensorMsg.PageDataReceived >> MapSensorData)

    Loadable.Loading session, cmd

let pageDataToDataModel (session: UserSession) (data: SensorDetailPage.Data) : DataModel =
    let graphDateRange =
        SensorDetailPage.mapGraphVisualisation DateRangePicker.initWithTwoDays data.Visualisations
    (*match (SensorType.fromModel data.SensorModel) with
        | SensorType.Air
        | SensorType.RainFall
        | SensorType.WeatherStation ->
            SensorDetailPage.mapGraphVisualisation DateRangePicker.initWithTwoDaysAndForecast data.Visualisations
        | SensorType.Soil
        | SensorType.LeafletMoisture
        | SensorType.WindAverage
        | SensorType.PH -> SensorDetailPage.mapGraphVisualisation DateRangePicker.initWithTwoDays data.Visualisations*)

    let dateRanges = data.DownloadRangeIds |> List.choose getRangeFromId

    {
        Session = session
        Name = data.Name
        SensorId = data.Id
        CurrentData = data.CurrentData
        GraphRequestRunning = false
        DownloadRequestRunning = false
        DownloadRange = List.head dateRanges
        AvailableDownloadRanges = dateRanges
        GraphDateRange = graphDateRange
        HistoryGraphData = NotLoaded
    }

let onDetailPageDataReceived session (response: SensorDetailPage.Response) : PageModel =
    match response with
    | SensorDetailPage.Response.NotFound -> PageModel.NotFound
    | SensorDetailPage.Response.Unauthenticated -> PageModel.NotAuthenticated
    | SensorDetailPage.Response.Unauthorized -> PageModel.NotAllowed
    | SensorDetailPage.Response.NotActive -> PageModel.NotActive
    | SensorDetailPage.Response.Data data -> PageModel.Data(pageDataToDataModel session data)


let createHistoricRequestData (sensor: int) (startDate: DateTime) (endDate: DateTime) = {
    Range = {
        Start = startDate
        End = endDate
    }

    SensorId = sensor
}

let createRequestHistoricDataCmd data =
    Cmd.OfAsync.perform api.getHistoricMapSensorData data (MySensSensorMsg.HistoricDataReceived >> MapSensorData)

let toDatePickerModel (newState: DatePicker.Types.State) (date: DateTime option) = {
    State = newState
    CurrentDate = date
}


let toGraphDataList (data: GraphDataDto) =
    if isGraphDataEmpty data then
        NoData
    else
        let graphData =
            match data with
            | Air airData -> airData |> graphDataDtoToAirGraphData |> AirList
            | Soil soilData -> soilData |> graphDataDtoToGroundGraphData |> GroundList
            | RainFall rainFallData -> rainFallData |> graphDataDtoToRainFallGraphData |> RainFallList
            | LeafletMoisture leafletMoistureData ->
                leafletMoistureData |> graphDataToLeafletMoistureData |> LeafletMoistureList
            | SoilPh soilPhData -> soilPhData |> graphDataToSoilPhGraphData |> SoilPhList
            | AverageWindSpeed averageWindSpeedData ->
                averageWindSpeedData
                |> graphDataToAverageWindSpeedGraphData
                |> AverageWindSpeedList
            | WeatherStation data -> data |> graphDataDtoToWeatherStationGraphData |> WeatherStationList

        HistoryGraphData.Data graphData

let private createExcelFileName (requestData: SensorDataRequest) (data: DataModel) =
    sprintf
        "%s_%s-%s.xlsx"
        data.Name
        (DateTime.toDateFileNameString requestData.Range.Start)
        (DateTime.toDateFileNameString requestData.Range.End)


let update (msg: MySensSensorMsg) (model: Model) : Model * Cmd<Msg> =
    match msg, model with
    | PageDataReceived response, Loadable.Loading loadingModel ->
        let dataModel = onDetailPageDataReceived loadingModel response

        match dataModel with
        | Data data ->
            match data.GraphDateRange with
            | SensorDetailPage.Allowed _ ->
                let now = DateTime.Now

                let startDate = now |> DateTime.addDays -1 |> DateTime.startOfDay
                let endDate = now |> DateTime.addDays 1 |> DateTime.startOfDay

                let requestData = createHistoricRequestData data.SensorId startDate endDate

                Loadable.Data(Data { data with GraphRequestRunning = true }),
                Cmd.OfAsync.perform
                    api.getHistoricMapSensorData
                    requestData
                    (MySensSensorMsg.HistoricDataReceived >> MapSensorData)
            | SensorDetailPage.NotAllowed -> Loadable.Data dataModel, Cmd.none
        | _ -> Loadable.Data dataModel, Cmd.none
    | HistoricDataReceived historicData, Loadable.Data(Data data) ->
        let newGraphData = toGraphDataList historicData

        let newData = {
            data with
                HistoryGraphData = newGraphData
                GraphRequestRunning = false
        }

        Loadable.Data(Data newData), Cmd.none
    | DownloadDateRangeChanged selectedRange, Loadable.Data(Data data) ->
        Loadable.Data(Data { data with DownloadRange = selectedRange }), Cmd.none
    | MySensSensorMsg.GraphDateRangeChanged dates, Loadable.Data(Data data) ->
        let newGraphDateRange =
            SensorDetailPage.mapGraphVisualisation (DateRangePicker.updateData dates) data.GraphDateRange

        Loadable.Data(Data { data with GraphDateRange = newGraphDateRange }), Cmd.none
    | MySensSensorMsg.RequestData requestData, Loadable.Data(Data data) ->
        Loadable.Data(Data { data with GraphRequestRunning = true }), createRequestHistoricDataCmd requestData
    | StartDownload requestData, Loadable.Data(Data data) ->
        let authenticatedRequest = {
            SessionKey = data.Session.SessionKey
            Data = requestData
        }

        Loadable.Data(Data { data with DownloadRequestRunning = true }),
        Cmd.OfAsync.perform
            api.downloadMapSensorData
            authenticatedRequest
            (fun response -> MySensSensorMsg.Download(requestData, response) |> MapSensorData)
    | MySensSensorMsg.Download(requestData, response: AuthenticatedResponse<byte[]>), Loadable.Data(Data data) ->
        let cmd =
            match response with
            | Result.Ok responseData ->
                let fileName = createExcelFileName requestData data

                responseData.SaveFileAs(fileName, "application/vnd.ms-excel")

                Cmd.none
            | Result.Error _ -> Toast.create "Ein Fehler ist aufgetreten beim Herunterladen" |> Toast.error

        Loadable.Data(Data { data with DownloadRequestRunning = false }), cmd
    | _ -> model, Cmd.none

let currentDataBox (data: MapSensorData) = [
    (Heading.h3 [
        Heading.Modifiers [
            Modifier.TextAlignment(Screen.All, TextAlignment.Option.Centered)
        ]
    ] [ str "Aktuelle Daten" ])

    yield!
        match data with
        | NoDataAvailable
        | NoPublicData -> []
        | AirData air -> currentAirDataBox air
        | GroundData ground -> currentGroundDataBox ground
        | RainFallData rainFall -> currentRainfallDataBox rainFall
        | LeafletMoistureData leafletMoistureSensorData -> currentLeafletMoistureDataBox leafletMoistureSensorData
        | SoilPhData data -> currentSoilPhDataBox data
        | AverageWindSpeedData data -> currentAverageWindSpeedDataBox data
        | WeatherStationData data -> []
]

let downloadBox dispatch (sensorId: int) (model: DataModel) =
    let currentTime = DateTime.Now

    let requestData =
        createHistoricRequestData
            sensorId
            (model.DownloadRange.StartTime currentTime)
            (model.DownloadRange.EndTime currentTime)

    let buttonOptions = [
        color.isLink
        if model.DownloadRequestRunning then
            button.isLoading
        else
            ()

        prop.onClick (fun _ -> dispatch (MySensSensorMsg.StartDownload requestData |> MapSensorData))
    ]

    let content =
        Bulma.columns [
            columns.isVCentered
            prop.children [
                Bulma.column [
                    column.isNarrow
                    prop.children [ Html.p [ prop.text "Datumsbereich:" ] ]
                ]
                Bulma.column [
                    DateRangeSelect.view
                        model.DownloadRange
                        ((MySensSensorMsg.DownloadDateRangeChanged >> MapSensorData) >> dispatch)
                        model.AvailableDownloadRanges
                ]
                Bulma.column [
                    column.isNarrow
                    prop.children [
                        Bulma.button.button [ yield! buttonOptions; prop.text "Download" ]
                    ]
                ]
            ]
        ]

    Bulma.box [
        (Heading.h3 [
            Heading.Modifiers [
                Modifier.TextAlignment(Screen.All, TextAlignment.Option.Centered)
            ]
        ] [ str "Download der Daten" ])
        content
    ]


let getGraphs (data: SensorGraphDataList) =
    match data with
    | AirList air -> airDataGraphs air
    | GroundList ground -> groundDataGraphs ground
    | RainFallList rainFall -> rainFallDataGraphs rainFall
    | LeafletMoistureList moisture -> leafletMoistureDataGraphs moisture
    | SoilPhList soilPh -> soilPhDataGraphs soilPh
    | AverageWindSpeedList averageWind -> averageWindSpeedDataGraphs averageWind
    | WeatherStationList weatherStation -> weatherStationDataGraphs weatherStation

let createEnabledButtonOptions dispatch (sensorId: int) (startDate: DateTime) (endDate: DateTime) =
    let requestData =
        createHistoricRequestData
            sensorId
            (DateTime.startOfDay startDate)
            (endDate |> DateTime.addDays 1 |> DateTime.startOfDay)

    [
        Button.Disabled false
        Button.OnClick(fun _ -> dispatch (MySensSensorMsg.RequestData requestData |> MapSensorData))
    ]

let createDatePicker (rangePicker: DateRangePicker.Data) (model: DataModel) dispatch =
    let buttonOptions =
        Option.map2 (createEnabledButtonOptions dispatch model.SensorId) rangePicker.Start rangePicker.End
        |> Option.defaultValue [ Button.Disabled true ]

    let buttonOptions =
        List.append buttonOptions [
            Button.IsLink
            Button.IsLoading model.GraphRequestRunning
        ]

    Column.column [ Column.Width(Screen.All, Column.IsFull) ] [
        Columns.columns [ Columns.IsVCentered ] [
            Column.column [ Column.Width(Screen.All, Column.IsNarrow) ] [ p [] [ str "Datumsbereich:" ] ]
            Column.column [] [
                DateRangePicker.view
                    "Von/Bis Datumsbereich auswählen"
                    ((MySensSensorMsg.GraphDateRangeChanged >> MapSensorData) >> dispatch)
                    rangePicker
            ]
            Column.column [ Column.Width(Screen.All, Column.IsNarrow) ] [ Button.button buttonOptions [ str "Laden" ] ]
        ]
    ]

let graphsView (data: HistoryGraphData) =
    match data with
    | NotLoaded -> []
    | NoData -> [
        createFullColumn (boxStringSubtitle "Es wurden keine Daten im ausgewählten Zeitraum gefunden")
      ]
    | HistoryGraphData.Data data -> getGraphs data

let graphBoxContent dispatch (model: DataModel) =
    let heading =
        Heading.h3 [
            Heading.Modifiers [
                Modifier.TextAlignment(Screen.All, TextAlignment.Option.Centered)
            ]
        ] [ str "Visualisierung der Daten" ]

    let graphsContent =
        match model.GraphDateRange with
        | SensorDetailPage.Allowed dateRange ->
            let datePicker = createDatePicker dateRange model dispatch

            Columns.columns [ Columns.IsMultiline ] [
                datePicker
                yield! graphsView model.HistoryGraphData
            ]
        | SensorDetailPage.NotAllowed ->
            boxStringSubtitle
                "Für die Darstellung der Verlaufsgrafiken ist das Paket \"Historie und erweiterter Download\" notwendig"

    Box.box' [] [ heading; graphsContent ]

let dataView dispatch (model: DataModel) =
    let boxes = [
        Box.box' [] (currentDataBox model.CurrentData)
        graphBoxContent dispatch model
        downloadBox dispatch model.SensorId model
    ]

    let content = [
        centeredHeading model.Name
        Columns.columns [ Columns.IsMultiline ] (List.map createFullColumn boxes)
    ]

    Container.container [ Container.isFullWidth ] content

let pageView dispatch (model: PageModel) =
    match model with
    | NotFound -> headingOnlyPageContent "Der Sensor wurde nicht gefunden"
    | NotActive -> headingOnlyPageContent "Dieser Sensor ist nicht aktiv"
    | NotAllowed -> headingOnlyPageContent "Sie dürfen diese Seite nicht ansehen"
    | NotAuthenticated -> headingOnlyPageContent "Sie sind aktuell nicht eingeloggt, bitte laden Sie diese Seite neu"
    | Data data -> dataView dispatch data


let view (model: Model) dispatch = Loadable.view (pageView dispatch) model